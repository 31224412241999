$gap: 35px;

.c-showcase {
    position: relative;
    width: 100%;
    height: 100vh;
    height: 100dvh;

    body.loggedin & {
        height: calc(100dvh - 48px);
    }

    .u-media-bg {
        position: absolute;
        inset: 0;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        z-index: 1;
        max-width: $contentWidth;
        width: 100%;
        padding: 80px $pageGutter;
        margin: 0 auto;
        height: 100dvh;

        @include min(sm) {
            padding-top: 160px;
            padding-bottom: 160px;
        }

        h1 {
            font-size: 3.4rem;
            line-height: 1.1;

            @include min(xs) {
                font-size: 5.6rem;
            }

            @include min(md) {
                font-size: 7rem;
            }
        }
    }

    body.loggedin &__content {
        height: calc(100dvh - 48px);
    }

    &__video {
        position: absolute;
        top: $gap;
        bottom: $gap;
        left: 50%;
        width: calc(100% - #{$gap * 2});
        aspect-ratio: 16 / 9;
        max-height: calc(100dvh - #{$gap * 2});
        height: 100%;
        pointer-events: none;
        opacity: 0;
        z-index: $fixedLayer;
        transform: translateX(-50%);
        padding: 0;
        transition: opacity 450ms $easeOut;

        video {
            position: absolute;
            top: 50%;
            left: 50%;
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            aspect-ratio: 16 / 9;
            transform: translate(-50%, -50%) scale(0.5);
            transform-origin: center;
            transition: transform 650ms $easeOutCubic;
        }

        &.is-active {
            pointer-events: auto;
            opacity: 1;

            video {
                transform: translate(-50%, -50%);
            }
        }
    }

    &__video-bg {
        position: absolute;
        inset: $gap * -1;
        background-color: rgba(0, 0, 0, 0.75);
    }

    body.loggedin &__video {
        max-height: calc(100dvh - (#{$gap * 2} + 48px));
    }

    &__pause-btn {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 48px;
        height: 48px;
        color: var(--neutral-100);
        background-color: transparent;
        transition: color $userInactive $easeOut;

        &:hover {
            color: var(--primary-50);
            transition-duration: $userActive;
        }

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 24px;
            height: 24px;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: opacity 400ms $easeOut;
        }

        svg[data-type='play'] {
            opacity: 1;
        }

        &.is-paused svg[data-type='play'] {
            opacity: 0;
        }

        svg[data-type='pause'] {
            opacity: 0;
        }

        &.is-paused svg[data-type='pause'] {
            opacity: 1;
        }
    }

    &__close-btn {
        position: absolute;
        top: 0;
        right: 0;
        height: 48px;
        width: 48px;
        background-color: transparent;
        padding: 0;
        color: var(--neutral-100);
        z-index: 1;
        transition: color $userInactive $easeOut;

        &:hover {
            color: var(--primary-50);
            transition-duration: $userActive;
        }

        .u-hamburger {
            --burger-size: 48px;
        }
    }

    &__btn {
        background-color: transparent;
        color: inherit;
        display: inline-flex;
        align-items: center;
        gap: 8px;
        padding: 0;
        margin-top: 32px;

        svg {
            width: 32px;
            height: 32px;
            color: var(--neutral-100);
            fill: var(--neutral-0);
            transition: color $userInactive $easeOut;
        }

        &:hover svg {
            color: var(--tertiary-50);
            transition-duration: $easeOut;
        }
    }
}
