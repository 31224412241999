.u-media {
    --image-width: 16;
    --image-height: 9;

    position: relative;

    &[data-size='3/4'] {
        --image-width: 4;
        --image-height: 3;
    }

    &[data-size='tall'] {
        --image-width: 9;
        --image-height: 16;
    }

    &[data-size='1200/628'] {
        --image-width: 1200;
        --image-height: 628;
    }

    &[data-size='1/1'],
    &[data-size='circle'] {
        --image-width: 1;
        --image-height: 1;
    }

    &[data-size='circle'] {
        overflow: hidden;
        border-radius: 50%;
    }

    &__wrap {
        position: relative;
        overflow: hidden;

        &:before {
            content: '';
            display: block;
            padding-bottom: calc(var(--image-height) / var(--image-width) * 100%);
        }
    }

    img,
    iframe,
    svg,
    video,
    [data-youtube-id] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &[data-type='contain'] img {
        object-fit: contain;
    }
}
