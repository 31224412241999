.c-slider {
    position: relative;
    grid-column: full-bleed;
    padding-bottom: 40px;

    &[data-dots='false'] {
        padding-bottom: 0;
    }

    &__viewport {
        overflow: hidden;
    }

    &__track {
        display: flex;

        > * {
            flex: 0 0 100%;
        }
    }

    &[data-slides-to-show='4'] &__track > * {
        flex: 0 0 50%;

        @include min(md) {
            flex: 0 0 25%;
        }
    }

    &__dots {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
    }

    &__dot {
        position: relative;
        width: 70px;
        height: 40px;

        + * {
            margin-left: 4px;
        }

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: get-color('neutral', 0);
        }

        &:hover:before,
        &.is-active:before {
            background-color: get-color('secondary', 50);
        }
    }

    &[data-dots='circle'] &__dot {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        overflow: hidden;

        + * {
            margin-left: 6px;
        }

        &:before {
            height: 100%;
        }
    }

    &--insight {
        padding-bottom: 0;

        .c-slider {
            &__dots {
                bottom: auto;
                left: auto;
                right: 20px;
                top: 20px;
            }

            &__dot {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                overflow: hidden;

                + * {
                    margin-left: 8px;
                }

                &:before {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
