.c-roles {
    &__link {
        --role-color: #{get-color('neutral', 100)};

        display: block;
        position: relative;
        overflow: hidden;
        padding: 30px 15px;
        color: var(--role-color);
        transition: color $userInactive $easeOut;

        @media (prefers-reduced-motion: reduce) {
            transition: none !important;
        }

        h2 {
            color: currentColor !important;
        }

        @include min(sm) {
            padding: 60px 50px;
        }

        @include min(md) {
            padding: 120px 100px;
        }

        &[data-hover-active] {
            --role-color: #{get-color('fontColor')};

            color: var(--role-color);
            transition-duration: $userActive;
        }

        > * {
            position: relative;
            z-index: 1;
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &:after {
            transition: transform 650ms $easeOutExpo;
            background-color: get-color('neutral', 0);

            @media (prefers-reduced-motion: reduce) {
                transition: none !important;
            }
        }

        &:before {
            background-color: get-color('secondary', 50);
        }

        &[data-hover-active]:after {
            transition-duration: 950ms;
            transform: translateX(100%);
        }

        &[data-hover-active] h2 {
            color: get-color('neutral', 0) !important;
        }

        .u-btn {
            --font-color: var(--role-color);
            --color: var(--secondary-50);
        }

        &[data-hover-active] .u-btn {
            color: var(--font-color);

            &:before {
                background-color: currentColor;
            }

            &:after {
                color: var(--secondary-50);
            }
        }
    }
}
