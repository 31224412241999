.u-nav {
    a {
        font-weight: 700;
        padding: 12px 11px;
        display: block;
        white-space: nowrap;

        @include min(md) {
            color: get-color('neutral', '100');
            padding: 12px 0.25em;

            &:last-child {
                padding-right: 0;
            }
        }

        &:after {
            content: '';
            position: static;
            display: inline-block;
            margin-left: 3px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: currentColor;
            opacity: 0;
            transform: none;
            transition: opacity $userInactive $easeOut;
        }

        &:hover {
            color: currentColor;

            @include min(md) {
                color: get-color('neutral', '100');
            }
        }

        &:hover:after {
            opacity: 1;
            transform: none;
        }

        &[data-active]:after {
            background-color: get-color('primary', '50');
            opacity: 1;
        }
    }

    [data-start-color='dark'] & {
        a {
            color: get-color('fontColor');

            &:hover {
                color: get-color('fontColor');
            }
        }
    }
}
