.c-social {
    display: flex;
    padding-top: 10px;

    &[data-align='right'] {
        @include min(sm) {
            justify-content: flex-end;
        }
    }

    &[data-align='center'] {
        justify-content: center;
    }

    &[data-size='large'] {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    > * + * {
        margin-left: 10px;
    }

    & &__link {
        --transition-duration: #{$userInactive};

        fill: get-color('neutral', 0);
        color: get-color('secondary', 50);

        &:hover {
            --transition-duration: #{$userActive};

            fill: get-color('secondary', 50);
            color: get-color('neutral', 0);
        }

        &:before {
            display: none;
        }
    }

    &[data-size='large'] &__link {
        position: relative;

        @include min(md) {
            width: 96px;
            height: 96px;
        }
    }

    &[data-size='large'] &__link:before {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 90%;
        height: 90%;
        background-color: currentColor;
        border-radius: 50%;
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.6);
        transition: opacity var(--transition-duration) $easeOut,
            transform var(--transition-duration) $easeOut;

        @media (prefers-reduced-motion: reduce) {
            transition: none !important;
        }

        @include min(md) {
            content: '';
        }
    }

    &[data-size='large'] &__link:hover:before {
        opacity: 1;
        transform: translate(-50%, -50%);
    }

    svg {
        position: relative;
        z-index: 1;
        width: 48px;
        height: 48px;
        transition: transform var(--transition-duration) $easeOut;

        @media (prefers-reduced-motion: reduce) {
            transition: none !important;
        }
    }

    &[data-size='large'] svg {
        @include min(md) {
            width: 96px;
            height: 96px;
        }
    }

    &[data-size='large'] &__link:hover svg {
        transform: scale(0.85);
    }
}
