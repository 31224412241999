.u-cite {
    font-style: normal;

    &__name {
        font-size: $fontSize;
        font-weight: 700;
        line-height: 1;
    }

    &__role {
        margin-top: 5px;
        font-size: 1.4rem;
        line-height: $headerLineHeight;
    }

    .c-project-team &__role {
        margin-top: 3px;
    }
}
