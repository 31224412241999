// Prevent interactive events triggering inside of interactive elements,
// so we can just tracking clicks on buttons instead of having to handle the click of the svg whithin wht button

a,
select,
button,
label {
    > * {
        pointer-events: none;
    }
}

button,
[role='button'],
label,
select {
    user-select: none;
}
