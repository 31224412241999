.c-image-card {
    --transition-duration: #{$userInactive};
    --easing: #{$easeOut};

    &:hover {
        --transition-duration: #{$userActive};
    }

    &__container {
        position: relative;
        height: 305px;
        width: 100%;
        display: flex;
        align-items: flex-end;

        @include min(sm) {
            height: 405px;
        }
    }

    &__background {
        overflow: hidden;

        &,
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        img {
            object-fit: cover;
            transition: transform var(--transition-duration) var(--easing);
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(
                to top,
                Rgba(0, 0, 0, var(--grad-opacity, 0.6)),
                rgba(0, 0, 0, 0) 75%
            );
        }
    }

    &:hover &__background img {
        transform: scale(1.1);
    }

    &__content-wrap {
        position: absolute;
        top: 30px;
        bottom: 30px;
        left: 30px;
        right: 30px;
        z-index: 1;
        overflow: hidden;
        display: flex;
        align-items: flex-end;

        @include min(md) {
            top: 50px;
            bottom: 50px;
            left: 40px;
            right: 40px;
        }
    }

    &__content {
        position: relative;

        h2 {
            padding-top: 0;
            max-width: 20ch;

            &:before {
                display: none;
            }
        }
    }

    .u-small-header {
        position: relative;
        display: inline-block;

        &:before {
            content: '';
            position: absolute;
            top: -10px;
            left: 0;
            width: calc(100% - 2px);
            height: 2px;
            background-color: currentColor;
            transform-origin: left;
            transform: scaleX(0);
            transition: transform var(--transition-duration) var(--easing);
        }

        + span {
            display: block;
        }
    }

    &:hover .u-small-header:before {
        transform: none;
    }

    @media (prefers-reduced-motion: reduce) {
        .u-small-header:before,
        &__background img {
            transition: none !important;
        }
    }
}
