:root {
    @include colorVars;
    @include fontSizes;
    @include space;

    --space: var(--space-600, $baseSpace);
    --scroll-duration: #{$scrollIn};
    --scroll-ease: #{$easeOutExpo};
    --scroll-load-delay: #{$scrollLoadedDelay};
}
