.l-thirds {
    grid-column: full-bleed;
    display: grid;
    gap: var(--space, $gutter) var(--column, $gutter);

    @include min(sm) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    &[data-width='content'] {
        grid-column: content;
    }

    &[data-mobile='halves'] {
        grid-template-columns: repeat(2, minmax(0, 1fr));

        @include min(sm) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }
}

.l-two-thirds {
    grid-column: content;
    display: grid;
    gap: var(--space, $gutter) var(--column, $gutter);

    @include min(sm) {
        grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    }

    &[data-width='full-bleed'] {
        grid-column: full-bleed;
    }
}

.l-third-two-thirds-right-bleed {
    grid-column: cover-page;

    > * {
        grid-column: content;

        @include min(sm) {
            &:first-child {
                margin-right: $gutter / 2;
                grid-column: third;
            }

            &:last-child {
                margin-left: $gutter / 2;
                grid-column: third2-start / full-bleed-end;
            }
        }
    }
}
