.c-square-5050 {
    position: relative;

    &--below-mobile {
        order: 1;

        @include min(sm) {
            order: 0;
        }
    }

    &:nth-child(2n + 1) &__container {
        margin-left: auto;
    }

    &__container {
        width: 100%;
        padding: 60px $pageGutter;

        @include min(sm) {
            padding: 40px;
            min-height: 720px;
            max-width: 720px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    &__grid {
        min-height: 400px;

        @include min(sm) {
            min-height: 720px;
        }
    }

    &__item {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &__grid-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;

        &:first-child {
            opacity: 1;
        }

        &:before {
            content: '';
            display: block;
            padding-bottom: ratio(420, 360);

            @include min(sm) {
                display: none;
            }
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        max-width: 32ch;

        h2 {
            padding-top: 0;

            &:before {
                display: none;
            }
        }
    }
}
