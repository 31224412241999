.u-checkbox {
    & + & {
        margin-top: 13px;
    }

    &--details + &--details {
        margin-top: 30px;
    }

    input {
        @include visually-hidden();
    }

    label {
        user-select: none;
        position: relative;
        padding-left: 24px + 8px;
        text-transform: none;
        letter-spacing: 0;
        font-weight: 400;
        font-size: 1.6rem;

        > * {
            pointer-events: none;
        }

        a {
            pointer-events: auto;
        }

        &:before,
        svg {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 24px;
            height: 24px;
        }

        &:before {
            content: '';
            border: 1px solid get-color('neutral', 0);
            background-color: get-color('neutral', 100);
        }

        svg {
            color: get-color('neutral', 0);
            opacity: 0;
            transition: opacity $userActive $easeOut;
        }
    }

    &--details label {
        font-weight: 700;
        padding-left: 24px + 12px;
    }

    &--details p {
        font-size: 1.4rem;
        padding-left: 24px + 12px;
        padding-top: 14px;
    }

    &:hover svg {
        opacity: 0.2;
    }

    input:checked + label svg {
        opacity: 1;
    }

    input.focus-visible + label {
        outline: 2px solid get-color('uniques', 'focus');
        outline-offset: 2px;
    }

    input:focus-visible + label {
        outline: 2px solid get-color('uniques', 'focus');
        outline-offset: 2px;
    }

    .u-form__group[data-error] & label:before {
        border-color: get-color('quaternary', 50);
    }
}
