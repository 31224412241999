.c-404 {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    padding-top: 80px;
    padding-bottom: block-space(700);

    @include min(sm) {
        padding-top: 185px;
    }

    &__content {
        max-width: 50ch;
        margin: 0 auto;

        @include min(md) {
            max-width: 45ch;
        }

        h2 {
            padding: 0;
        }
    }

    &__bug {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12rem;
        font-weight: 700;
        line-height: 0.8;

        @include min(sm) {
            font-size: 17rem;
        }

        span {
            margin: 0 0.1136em;
        }

        svg {
            width: 0.727em;
            height: 0.727em;
            color: get-color('neutral', 0);
            fill: get-color('neutral', 100);
        }
    }
}
