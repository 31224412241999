.u-btn {
    --color: #{get-color('primary', '50')};
    --transition-duration: #{$userInactive};
    --font-color: #{get-color('fontColor')};

    @media (prefers-reduced-motion: reduce) {
        --transition-duration: 1ms;
    }

    body.editmode & {
        --transition-duration: 1ms;
    }

    position: relative;
    line-height: 1;
    font-weight: 700;
    text-align: left;
    text-decoration: none;
    color: var(--font-color);
    background-color: transparent;
    border: 0;
    padding: 7px 0 7px 45px;
    min-height: 30px;
    transition: transform var(--transition-duration) $easeOut;

    @include min(md) {
        padding: 5px 0 5px 45px;
    }

    [class*='h-text--'] & {
        --font-color: currentColor;
    }

    &:disabled {
        opacity: 0.5;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 30px;
        background-color: var(--color);
        border-radius: 50%;
        transform: scale(0.825);
        transition: transform var(--transition-duration) $easeOut,
            background-color var(--transition-duration) $easeOut;
    }

    &:after {
        content: '';
        position: absolute;
        top: 11px;
        left: 10px;
        width: 8px;
        height: 8px;
        transform: rotate(45deg);
        color: get-color('neutral', 0);
        border-top: 2px solid currentColor;
        border-right: 2px solid currentColor;
        transition: color var(--transition-duration) $easeOut;
    }

    &[data-arrow='down']:after {
        transform: rotate(135deg);
        top: 10px;
        left: 11px;
    }

    .c-card:hover &,
    &:not(:disabled):hover {
        --transition-duration: #{$userActive};

        color: var(--font-color);
        transform: translateX(0.8em);

        body.editmode & {
            --transition-duration: 1ms;
        }

        &:before {
            transform: none;
            background-color: currentColor;
        }

        &:after {
            color: get-color('neutral', 100);
        }

        @media (prefers-reduced-motion: reduce) {
            transform: none !important;

            &,
            &:before,
            &:after {
                transition: none !important;
            }
        }
    }

    &[data-arrow='down']:not(:disabled):hover {
        transform: none;
    }

    .h-text--neutral-100 &:not(:disabled):hover:after {
        color: get-color('neutral', 0);
    }

    &.focus-visible {
        outline: 2px solid get-color('uniques', 'focus');
        outline-offset: 2px;
    }

    &:focus-visible {
        outline: 2px solid get-color('uniques', 'focus');
        outline-offset: 2px;
    }

    &[data-color='secondary'] {
        --color: #{get-color('secondary', '50')};
    }

    &[data-color='grey'] {
        --color: #{get-color('neutral', '0')};

        &:after {
            color: get-color('neutral', 100);
        }
    }

    &[data-type='muted'] {
        font-weight: 400;
    }

    &__loading-icon {
        animation: rotate 1s infinite linear;
        position: absolute;
        top: 0;
        right: -40px;
        height: 30px;
        width: 30px;

        @media (prefers-reduced-motion: reduce) {
            animation: none !important;
        }
    }

    &:not(.is-loading) &__loading-icon {
        display: none;
    }

    &.is-loading {
        pointer-events: none;
    }

    &--icon {
        font-weight: normal;
        padding-left: 0;

        &:before,
        &:after {
            display: none;
        }

        svg {
            height: 25px;
            padding-right: 5px;
            vertical-align: middle;
            width: 25px;
        }
    }
}
