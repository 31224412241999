//====================
//  Media queries
//====================
// uses common breakpoint map or enter your own width in px, these mixins will convert them to em
//
// exmaple of use:
// @include min(md) {
//     ...
// }
//
// or
//
// @include min(960) { for 960px width
//     ...
// }

// min width media quiery that can take either a number or a key from the common breakpoints
@mixin min($width) {
    $minWidth: $width;

    @if type-of($minWidth) != 'number' {
        $minWidth: map-get($commonBreakpoints, $minWidth);
    }

    $minWidth: $minWidth / 16 * 1em;

    @media (min-width: #{$minWidth}) {
        @content;
    }
}

// max width media quiery that can take either a number or a key from the common breakpoints
@mixin max($width) {
    $maxWidth: $width;

    @if type-of($maxWidth) != 'number' {
        $maxWidth: map-get($commonBreakpoints, $maxWidth);
        $maxWidth: $maxWidth - 1;
    }

    $maxWidth: $maxWidth / 16 * 1em;

    @media (max-width: #{$maxWidth}) {
        @content;
    }
}

// media quiery for between two widths that can take either a number or a key from the common breakpoints
@mixin between($min, $max) {
    $minWidth: $min;
    $maxWidth: $max;

    @if type-of($maxWidth) != 'number' {
        $maxWidth: map-get($commonBreakpoints, $maxWidth);
        $maxWidth: $maxWidth - 1;
    }

    @if type-of($minWidth) != 'number' {
        $minWidth: map-get($commonBreakpoints, $minWidth);
    }

    $maxWidth: $maxWidth / 16 * 1em;
    $minWidth: $minWidth / 16 * 1em;

    @media (min-width: $minWidth) and (max-width: $maxWidth) {
        @content;
    }
}
