.c-header {
    --nav-height: #{$logoSize + 30px};

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: $navLayer;
    display: grid;
    grid-template-columns:
        1.5rem 1fr
        [content-start logo-start]
        minmax(0, 14rem)
        [logo-end nav-start]
        minmax(0, #{$contentWidth - 14rem})
        [content-end nav-end]
        1fr 1.5rem;
    background-color: transparent;
    transition: background-color $userActive $easeOut, transform $userActive $easeOut;
    // transform transition for alerts.scss

    @media (prefers-reduced-motion: reduce) {
        transition: none !important;
    }

    @include min(sm) {
        --nav-height: #{$logoSize\@sm + 20px};
    }

    @include min(sm) {
        pointer-events: none;
        background-color: transparent;
    }

    &[data-hide-nav] {
        background-color: get-color('neutral', 100);

        @include min(sm) {
            background-color: transparent;
        }
    }

    body.loggedin & {
        top: 45px;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: get-color('neutral', 0);
        opacity: 0;
        transition: opacity 250ms $easeOutCubic;
    }

    &.subnav-open:before {
        opacity: 1;
        transition-duration: 400ms;
    }

    &__logo {
        z-index: 2;
        grid-column: logo;
        pointer-events: auto;
        padding-top: 10px;
        padding-bottom: 10px;

        @include min(md) {
            padding-top: 40px;
        }

        > * {
            display: block;
        }
    }

    &__nav {
        grid-column: nav;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        overflow: hidden;
    }

    &__nav-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        overflow-y: auto;
        padding-top: var(--nav-height);
        padding-left: $pageGutter;
        padding-right: $pageGutter;
        background-color: get-color('neutral', '0');
        color: get-color('neutral', '100');
        transform: translateY(-100%);
        transition: transform 450ms $easeOutCubic;
        pointer-events: auto;

        @media (prefers-reduced-motion: reduce) {
            transition: none !important;
        }

        @include min(md) {
            padding-top: 0;
            display: flex;
            align-items: center;
            position: static;
            width: auto;
            height: auto;
            overflow-y: visible;
            padding-left: 0;
            padding-right: 4px;
            background-color: transparent;
            transform: none;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: var(--nav-height);
            background-color: get-color('neutral', '0');
            z-index: 1;
            transform: translateY(calc(100vh - var(--nav-height)));
            transition: transform 450ms $easeOutCubic;

            @media (prefers-reduced-motion: reduce) {
                transition: none !important;
            }

            @include min(md) {
                display: none;
            }
        }

        &[data-active],
        &[data-active]:after {
            transform: none;
            transition-duration: 600ms;
        }

        > * + * {
            @include min(md) {
                margin-left: 2.5em;
            }
        }

        > li + li {
            border-top: 1px solid get-color('neutral', 100);

            @include min(md) {
                border-top: 0;
            }
        }

        > li > a,
        > li .c-header__sub-nav-btn-wrap button:first-child {
            font-weight: 700;
            padding: 12px 11px;
            position: relative;
            display: flex;
            white-space: nowrap;
            overflow: hidden;
            align-items: center;
            color: currentColor;
            text-decoration: none;
            background-color: transparent;
            width: 100%;

            @include min(md) {
                z-index: 1;
                padding: 70px 0 40px;
                width: auto;
            }

            svg {
                width: 14px;
                height: 9px;
                margin-left: 8px;
                display: none;
                transition: transform $userInactive $easeOut;

                @include min(md) {
                    display: block;
                }
            }

            &:after {
                content: '';
                position: absolute;
                top: 64px;
                left: 0;
                width: 100%;
                height: 2px;
                transform: translateX(-110%);
                display: none;
                background-color: currentColor;
                transition: transform $userInactive $easeOut;

                @include min(md) {
                    display: block;
                }
            }

            &:hover:after,
            &[data-active]:after {
                transform: none;
                transition-duration: $userActive;
            }
        }
    }

    [data-subnav-active] &__sub-nav-btn-wrap button:first-child svg {
        transform: rotateX(180deg);
        transition-duration: $userActive;
    }

    &[data-hide-nav] &__nav-container {
        @include min(md) {
            transform: translateX(-150%);
        }
    }

    &__sub-nav {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-color: get-color('neutral', 0);
        color: get-color('neutral', 100);
        font-size: 1.8rem;
        pointer-events: none;
        transform: translateX(-100%);
        transition: transform 400ms $easeOutCubic;
        padding-top: var(--nav-height);
        z-index: 1;

        @include min(md) {
            opacity: 0;
            top: 100%;
            padding-top: 0;
            transform: none;
            transition: opacity 250ms $easeOutCubic;
        }

        [data-subnav-active] & {
            opacity: 1;
            transform: none;
            transition-duration: 400ms;
            pointer-events: auto;
        }

        &:focus-within {
            @include min(sm) {
                opacity: 1;
                transform: none;
                transition-duration: 600ms;
                pointer-events: auto;
            }
        }

        .u-btn {
            --font-color: get-color('neutral', 100);

            display: block;
            font-weight: 400;

            @include min(md) {
                padding-left: 40px;
            }
        }

        p + .u-btn {
            margin-top: 20px;
        }

        .u-btn:not(:disabled):hover:after {
            color: get-color('neutral', 0);
        }
    }

    &__sub-nav-container {
        max-width: $contentWidth + 3rem;
        width: 100%;
        padding: 12px 1.5rem 96px;
        margin: 0 auto;
        display: grid;
        gap: 17px;

        @include min(sm) {
            padding-top: 40px;
            gap: 30px;
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @include min(md) {
            grid-template-columns: repeat(4, minmax(0, 1fr));

            &--3-col,
            &[data-count='3'] {
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }
        }
    }

    &__sub-nav-group {
        border-top: 1px solid get-color('neutral', 100);
        padding-top: 17px;

        @include min(sm) {
            border-top: 0;
            padding-top: 0;
        }

        > li + li {
            margin-top: 18px;
        }

        li:last-child {
            @include max(sm) {
                margin-bottom: 13px;
            }
        }

        p,
        p + .u-btn {
            @include max(sm) {
                display: none;
            }
        }
    }

    &__title {
        font-weight: 700;
        font-size: 2rem;
        height: 30px;
        color: currentColor;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 0;
        line-height: 1.5;

        span:first-child {
            flex: 1 1 100%;
        }

        a {
            text-decoration: none;
            overflow: hidden;

            @include max(sm) {
                display: block;
                width: 100%;
            }

            &:after {
                left: 0;
                transform: translateX(-110%);
            }

            &:hover:after {
                transform: none;
            }
        }
    }

    &__title + *,
    &__sub-nav-group &__title + li {
        margin-top: 32px !important;
    }

    &__title-tag {
        font-size: 1.2rem;
        text-transform: uppercase;
        color: get-color('neutral', 0);
        line-height: 1;
        padding: 8px 20px;
        border-radius: 13px;
    }

    &__sub-nav-btn {
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        color: currentColor;

        @include min(md) {
            display: none;
        }

        svg {
            width: 12px;
            height: 12px;
        }

        &-wrap {
            display: flex;
            justify-content: space-between;
        }
    }

    &__back-btn {
        background-color: transparent;
        display: flex;
        align-items: center;
        color: get-color('neutral', 100);
        font-weight: 700;
        font-size: 1.4rem;

        @include min(sm) {
            grid-column: span 2;
        }

        svg {
            width: 10px;
            height: 15px;

            + * {
                margin-left: 10px;
            }
        }

        @include min(md) {
            display: none;
        }
    }

    &__lets-talk-btn {
        position: absolute;
        top: 100%;
        left: 0;
        max-width: $contentWidth;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        opacity: 0;
        transition: opacity 250ms $easeOutCubic;

        @include min(sm) {
            top: calc(50% + 55px);
            left: 50%;
            transform: translate(-50%, -50%);
        }

        @include min(md) {
            top: calc(50% + 15px);
        }

        a {
            display: block;
            position: relative;
            padding: 15px 38px 15px 15px;
            text-decoration: none;
            font-size: 1.4rem;
            font-weight: 700;
            line-height: 1;
            text-transform: uppercase;
            pointer-events: auto;
            width: 100%;
            transition: opacity 250ms $easeOutCubic;

            @include min(sm) {
                width: auto;
                padding: 7px 38px 7px 18px;
                font-size: 1.2rem;
            }

            &.is-hidden {
                opacity: 0;
                transition-duration: 650ms;
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: get-color('tertiary', 50);
                transition: transform $userInactive $easeOut;

                @include min(sm) {
                    border-radius: 13px;
                }
            }

            &:hover:before {
                transition-duration: $userActive;

                @include min(sm) {
                    transform: scale(1.05, 1.2);
                }
            }

            &:after {
                display: none;
            }

            span {
                position: relative;
                z-index: 1;
            }

            svg {
                position: absolute;
                top: 50%;
                right: 15px;
                width: 19px;
                height: 12px;
                transform: translateY(-50%);
                transition: transform $userInactive $easeOut;

                @include min(sm) {
                    width: 12px;
                    height: 10px;
                }
            }

            &:hover svg {
                transform: translateY(calc(-50% + 4px));
                transition-duration: $userActive;

                @include min(sm) {
                    transform: translateY(calc(-50% + 2px));
                }
            }
        }
    }

    &[data-hide-nav] &__lets-talk-btn {
        opacity: 1;
        transition-duration: 650ms;
        transition-delay: 150ms;
    }
}
