$yellow-blue: linear-gradient(
    0deg,
    hsl(44, 78%, 58%) 0%,
    hsl(58, 52%, 55%) 19%,
    hsl(79, 48%, 60%) 27%,
    hsl(107, 43%, 66%) 34%,
    hsl(141, 44%, 65%) 41%,
    hsl(160, 46%, 63%) 47%,
    hsl(170, 44%, 62%) 53%,
    hsl(175, 43%, 61%) 59%,
    hsl(180, 41%, 60%) 66%,
    hsl(184, 43%, 61%) 73%,
    hsl(188, 43%, 61%) 81%,
    hsl(192, 43%, 62%) 100%
);

$yellow-red: linear-gradient(
    0deg,
    hsl(44, 78%, 58%) 0%,
    hsl(40, 80%, 59%) 20%,
    hsl(35, 82%, 60%) 29%,
    hsl(31, 82%, 61%) 36%,
    hsl(27, 81%, 61%) 43%,
    hsl(23, 79%, 62%) 50%,
    hsl(18, 78%, 62%) 57%,
    hsl(13, 76%, 62%) 64%,
    hsl(7, 73%, 62%) 71%,
    hsl(1, 68%, 62%) 80%,
    hsl(354, 65%, 59%) 100%
);

@keyframes gradFade {
    from {
        opacity: 100%;
    }

    to {
        opacity: 0;
    }
}

.c-newsletter-popup {
    @include max(sm) {
        &[open] &__aside {
            flex-direction: row-reverse;
            align-items: center;
            padding: 25px 0;
            flex: 1;
        }

        &[open] &__close {
            top: 45px;
            right: 15px;
            z-index: 9999;
        }

        &[open] &__form {
            flex-direction: column;
            justify-content: space-evenly;
            display: flex;

            .u-form__group + .u-form__group {
                margin-top: 30px;
            }

            .u-form__group + .u-btn {
                margin-top: 30px;
            }
        }

        &[open] &__content {
            padding-bottom: 35px;
        }
    }

    --trans-duration: #{$userInactive};

    position: fixed;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 940px;
    width: 100%;
    border: 0;
    padding: 0;
    opacity: 0;
    pointer-events: none;
    background-color: #fff;
    transform: translateY(50px);
    transition: opacity var(--trans-duration) $easeOut, transform var(--trans-duration) $easeOut;

    &[open] {
        --trans-duration: #{$userActive};
        display: block;
        opacity: 1;
        pointer-events: auto;
        transform: none;
        overflow: visible;
    }

    &::backdrop {
        background: rgba(0, 0, 0, 0.5);
    }

    &__wrap {
        display: flex;
    }

    &[open] &__wrap {
        @include max(sm) {
            flex-direction: column;
        }
    }

    &__aside {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 0 0 220px;
        padding: 93px 26px 50px;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &:before {
            background-image: $yellow-blue;
        }

        &:after {
            background-image: $yellow-red;
            animation-name: gradFade;
            animation-timing-function: $easeInOutCubic;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            animation-duration: 5000ms;
        }

        > * {
            z-index: 1;
        }
    }

    &__logo {
        position: relative;
        width: 163px;
        height: 55px;
        margin: 0 auto;
        color: get-color(primary, 0);
        padding-bottom: 25px;

        &:before {
            content: '';
            position: absolute;
            top: -14px;
            left: 0;
            width: 163px;
            height: 6px;
            background-color: currentColor;
        }

        svg {
            width: 163px;
            height: 55px;
        }
    }

    &__escape {
        color: get-color('neutral', 100);
        transform-style: preserve-3d;
        background-color: #000;
        border-radius: 50%;
        width: 118px;
        height: 118px;
        margin: 0 auto;
        transition: transform 650ms $easeOutCubic;

        &.is-active {
            transition-duration: 900ms;
            transform: rotateY(180deg);
        }

        &-front,
        &-back {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            backface-visibility: hidden;
            transform: translate(-50%, -50%);

            svg {
                width: 100%;
                height: 100%;
            }
        }

        &-back {
            transform: translate(-50%, -50%) rotateY(180deg);
            width: 50px;
            height: 40px;
        }
    }

    &__form {
        position: relative;
        overflow: hidden;
        flex: 1 1 100%;
        padding: 24px 15px;

        @include min(sm) {
            padding: 72px 95px;
        }

        .u-form__group + .u-form__group {
            margin-top: 64px;
        }

        .u-form__group + .u-btn {
            margin-top: 82px;

            &:hover:after {
                color: var(--neutral-100);
            }
        }
    }

    &__content {
        font-size: 2rem;
        padding-bottom: 95px;
    }

    &__close {
        position: absolute;
        top: -5px;
        right: 15px;
        color: #fff;
        background-color: transparent;
        transform: translate(50%, -100%);
    }

    &__success {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translateX(100%);
        transition: transform $userActive $easeOut;
        background-color: #fff;
        z-index: 1;
        display: flex;
    }

    &__success &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 72px 95px;
        flex-grow: 1;

        .u-btn {
            margin-top: auto;
        }
    }

    &__form.hide-form &__success {
        transform: none;
    }
}
