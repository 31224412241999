.c-card {
    --transition-duration: #{$userInactive};

    display: flex;
    width: 100%;

    .u-media + * {
        margin-top: space(600);
    }

    h3 + * {
        margin-top: space(800) !important;
    }

    h3 + .u-text-icon__list {
        padding-top: space(800);
    }

    .u-text-icon__list + .u-btn {
        margin-top: space(700);
    }

    img {
        transition: transform var(--transition-duration) $easeOut;
        transform-origin: center;
    }

    &:hover {
        --transition-duration: #{$userActive};
    }

    &[href]:hover img {
        transform: scale(1.1);
    }

    h3 {
        --line-height: 4px;

        position: relative;
        padding-top: calc(var(--line-height) + #{space('500')});
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 140px;
            height: var(--line-height);
            background-color: currentColor;
            transform-origin: left;
            transform: translateX(-100%);
            transition: transform var(--transition-duration) $easeOut;
        }
    }

    &[href]:hover h3:before {
        transform: none;
    }

    @media (prefers-reduced-motion: reduce) {
        h3:before,
        img {
            transition: none !important;
        }
    }

    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;

        .u-text-icon__list {
            margin-top: auto !important;
        }
    }

    &__list {
        > * {
            display: flex;
        }
    }
}
