.u-radio {
    input {
        @include visually-hidden();
    }

    label {
        user-select: none;
        position: relative;
        padding-left: 24px + 8px;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            border-radius: 50%;
            transform: translateY(-50%);
        }

        &:before {
            left: 0;
            width: 24px;
            height: 24px;
            border: 1px solid get-color('neutral', 0);
            background-color: get-color('neutral', 100);
        }

        &:after {
            left: 6px;
            width: 12px;
            height: 12px;
            background-color: get-color('neutral', 0);
            opacity: 0;
            transition: opacity $userActive $easeOut;
        }
    }

    input:checked + label:after {
        opacity: 1;
    }

    input.focus-visible + label {
        outline: 2px solid get-color('uniques', 'focus');
        outline-offset: 2px;
    }

    input:focus-visible + label {
        outline: 2px solid get-color('uniques', 'focus');
        outline-offset: 2px;
    }
}
