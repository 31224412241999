.c-alert {
    font-weight: 700;
    height: 115px;
    overflow: hidden;
    transition: transform $userActive $easeOut;
    position: relative;
    z-index: 50;
    font-size: 1.8rem;

    @include min(xs) {
        height: 60px;
    }

    &.is-closed {
        transform: translateY(-100%);
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            padding: 15px 0;
        }
    }

    &__close {
        background-color: transparent;
    }

    ~ .c-header:not([data-hide-nav]) {
        transform: translateY(115px);

        @include min(xs) {
            transform: translateY(60px);
        }
    }

    &.is-closed + .c-header,
    &.not-visible + .c-header {
        transform: none;
    }

    ~ main {
        transition: transform $userActive $easeOut;
    }

    &.is-closed ~ main {
        transform: translateY(-115px);

        @include min(xs) {
            transform: translateY(-60px);
        }
    }
}
