//====================
// Typography
//====================

// Font Families
//====================

// system font stacks
$sans-serif-system-stack: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
$serif-system-stack: serif;

// font families
$fontFamily: 'Gilroy', $sans-serif-system-stack;
$headerFontFamily: $fontFamily;

// Font Colors
//====================
$baseFontColor: #{get-color('neutral', 'black')};
$headerColor: $baseFontColor;

// weights
//====================
$fontWeight: 400;
$strongWeight: 700;
$headerWeight: 700;
$headerBoldWeight: 700;

// lineHeight
//====================
$headerLineHeight: 1.2;
$lineHeight: 1.5;

// Font sizes
//====================
$fontSize: 1.8rem;
$mdFontSize: 2rem;
$leadFontSize: 4rem;
$headerSmall: 1.4rem;
$smallFontSize: 1.2rem;

$headerSizes: (
    base: (
        h1: 3.8rem,
        h2: 3.2rem,
        h3: 2.2rem,
        h4: 2.2rem,
        h5: 2rem,
        h6: 2rem
    ),
    md: (
        h1: 5.6rem,
        h2: 3.6rem,
        h3: 2.2rem
    )
);
