h1 {
    font-size: var(--h1);
}

h2 {
    font-size: var(--h2);
}

h3 {
    font-size: var(--h3);
    letter-spacing: 0.02em;
}

h4 {
    font-size: var(--h4);
}

h5 {
    font-size: var(--h5);
}

h6 {
    font-size: var(--h6);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0;
    line-height: $headerLineHeight;
    font-weight: $headerWeight;
    // color: get-color('headerColor');

    strong {
        font-weight: $headerBoldWeight;
    }
}

h1 {
    line-height: 1;
    letter-spacing: -0.97px;
}
