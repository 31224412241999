@keyframes fadeInError {
    from {
        opacity: 0;
    }
}

.u-form {
    label {
        display: inline-block;
        font-size: $headerSmall;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        font-weight: 700;
        line-height: 1.2;
    }

    textarea {
        resize: vertical;
    }

    textarea,
    input,
    select {
        display: block;
        width: 100%;
        box-shadow: none;
        background-color: transparent;
        font-family: $fontFamily;
        font-size: $fontSize;
        border: 0;
        border-bottom: 2px solid get-color('neutral', 0);
        border-radius: 0;
        padding: 3px 0 5px;
        line-height: $lineHeight;
        transition: border-color $userInactive $easeOut;

        &:focus {
            outline: 0;
            border-bottom-color: get-color('secondary', 50);
            transition-duration: $userActive;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            border: 0;
            -webkit-text-fill-color: get-color('neutral', 'black');
            -webkit-box-shadow: 0 0 0 1000px get-color('neutral', 100) inset;
            transition: background-color 5000s ease-in-out 0s;
            border-bottom: 1px solid get-color('neutral', 'black');
        }

        .h-background--neutral-0 &:-webkit-autofill,
        .h-background--neutral-0 &:-webkit-autofill:hover,
        .h-background--neutral-0 &:-webkit-autofill:focus {
            -webkit-box-shadow: 0 0 0 1000px get-color('neutral', 0) inset;
            -webkit-text-fill-color: get-color('neutral', 100);
            border-bottom: 1px solid get-color('neutral', 100);
        }
    }

    [class*='h-text--'] & textarea,
    [class*='h-text--'] & input {
        color: currentColor;
        border-bottom-color: currentColor;
    }

    &__group[data-error] {
        textarea,
        input {
            border-bottom-color: get-color('quaternary', 50);
        }
    }

    &__group--checkbox + &__group {
        margin-top: 13px;
    }

    &__errors {
        position: relative;
        font-size: $fontSize;
        animation-name: fadeInError;
        animation-duration: 850ms;
        animation-timing-function: $easeOutCubic;
        animation-fill-mode: forwards;
        padding: 13px 16px;
        display: none;

        h3 + p {
            margin-top: 0 !important;
        }

        p + ul {
            margin-top: 15px;
        }

        &[data-active] {
            display: block;
        }

        > * {
            position: relative;
            z-index: 1;
        }

        + * {
            margin-top: 30px;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: get-color('state', 'error');
            z-index: 0;
        }

        p {
            font-weight: 700;
        }

        a {
            line-height: $headerLineHeight;
            font-weight: 400;
        }

        li + li {
            padding-top: 4px;
        }

        .u-btn {
            @include min(md) {
                padding: 5px 0 5px 35px;
            }
        }
    }

    .h-background--neutral-0 &__errors:before,
    .h-background--neutral-0 &__message:before {
        opacity: 0.8;
    }

    &__message {
        margin-top: space(400);
        font-size: 1.6rem;
        line-height: 1.3;
        padding-left: 20px;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 5px;
            left: 0;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: get-color('state', 'error');
        }
    }

    &__list,
    &__checkbox-list {
        border: 0;
        margin: 0;
        padding: 0;
        margin-left: -15px;
        margin-right: 0;
        width: calc(100% + 30px);

        @include min(sm) {
            display: flex;
            flex-wrap: wrap;
        }

        > * {
            margin-left: 15px;
            margin-right: 15px;
            padding: 5px 0;
        }

        legend {
            display: inline-block;
            font-size: $headerSmall;
            text-transform: uppercase;
            letter-spacing: 0.2em;
            font-weight: 700;
            line-height: 1.2;
            border: 0;
            padding-left: 0;
            padding-right: 0;
            margin-top: 0;
            margin-bottom: 0;
            flex: 1 1 100%;
        }
    }

    &__checkbox-list {
        position: relative;
        padding-bottom: 32px;

        @include min(sm) {
            display: block;
        }

        > * {
            padding-top: 0;
            padding-bottom: 0;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 15px;
            right: 15px;
            border-bottom: 2px solid get-color('neutral', 0);
            transition: border-color $userInactive $easeOut;
        }

        &:focus-within:after {
            border-bottom-color: get-color('secondary', 50);
            transition-duration: $userActive;
        }

        legend + * {
            margin-top: 30px;
        }
    }

    &__label-group {
        font-size: 1.6rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        label {
            margin-right: 8px;

            + * {
                margin-left: auto;
            }
        }
    }
}
