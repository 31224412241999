.c-sticky-image-5050 {
    padding-top: 80px;
    padding-bottom: 80px;

    @include min(md) {
        padding-top: var(--space);
        padding-bottom: 200px;
    }

    &__content {
        @include min(md) {
            font-size: 1.7rem;
            padding-top: 150px;
            padding-right: 75px;
        }
    }
}
