.c-cookie-category {
    &__heading {
        margin-top: var(--space-800);
    }

    &__table {
        table {
            border-collapse: collapse;
            font-size: var(--font-size);
            table-layout: fixed;
            width: 100%;
        }

        &--responsive {
            overflow-x: auto;

            table {
                min-width: 700px;
            }
        }

        th, td {
            text-align: left;
            padding: 10px;
            vertical-align: top;
            width: calc(60% / 3);

            &:last-child {
                width: 40%;
            }

            a {
                display: inline-block;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        thead {
            tr {
                border-bottom: 1px solid #000;
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid #eee;

                &:last-child {
                    border-bottom-color: transparent;
                }

                td {
                    &.c-cookie-category__table-no-data {
                        text-align: center;
                    }
                }
            }
        }
    }
}
