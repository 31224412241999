$commonBreakpointNames: map-keys($commonBreakpoints);

@each $sizeName, $size in $spaceScale {
    .u-margin-bottom[data-mb-size='#{$sizeName}'] {
        margin-bottom: #{$size};
    }

    @each $commonBreakpointName in $commonBreakpointNames {
        .u-margin-bottom[data-mb-size\@#{$commonBreakpointName}='#{$sizeName}'] {
            @include min(#{$commonBreakpointName}) {
                margin-bottom: #{$size};
            }
        }
    }
}

@each $blockSizeName, $blockSize in $blockSpaceScale {
    .u-margin-bottom[data-mb-size='#{$blockSizeName}'][data-mb-type='block'] {
        margin-bottom: #{$blockSize};
    }

    @each $commonBreakpointName in $commonBreakpointNames {
        .u-margin-bottom[data-mb-size\@#{$commonBreakpointName}='#{$blockSizeName}'][data-mb-type='block'] {
            @include min(#{$commonBreakpointName}) {
                margin-bottom: #{$blockSize};
            }
        }
    }
}
