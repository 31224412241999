.c-brand-5050 {
    &__container {
        align-items: center;
    }

    .c-testimonial:first-child {
        order: 1;

        @include min(sm) {
            order: 0;
        }

        @include min(md) {
            padding-right: 100px;
        }
    }
}
