.c-testimonial {
    margin: 0;
    position: relative;
    z-index: 1;

    &--image & {
        display: grid;
        grid-template-columns: minmax(0, 5fr) minmax(0, 5fr);
        grid-template-areas: 'title image' 'content content';
        gap: 30px;

        @include min(sm) {
            grid-template-columns: minmax(0, 4fr) 1fr minmax(0, 7fr);
            grid-template-areas: 'image ... title' 'image ... content';
        }
    }

    &--image .c-slider__dots {
        bottom: 0;

        @include min(sm) {
            left: auto;
            right: calc(50% + 5px);
        }

        @include min($gridBreakpoint) {
            right: calc(50% + 10px);
        }
    }

    &--image .c-slider {
        padding-bottom: 0;
    }

    &--image .c-slider__slide {
        display: grid;

        @include min($gridBreakpoint) {
            grid-template-columns: minmax(0, 19rem) 94rem minmax(0, 19rem);
            grid-template-areas: '... quote ...';
        }

        > * {
            grid-area: quote;
        }
    }

    &--image .c-team-listing__image {
        @include min(sm) {
            transform: scale(1.14) translateX(10px);
        }
    }

    &__image {
        grid-area: image;
        display: flex;
        align-items: center;

        > * {
            width: 100%;
        }
    }

    &__client-logo {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--tertiary-50);
            border-radius: 50%;
        }

        .u-media {
            z-index: 1;
            padding: 10%;
        }
    }

    &__content {
        grid-area: content;
    }

    &--image &__content {
        padding-bottom: 30px + 14px;
        padding-top: 25px;

        @include min(sm) {
            padding-top: 0;
            padding-bottom: 120px + 42px;
        }
    }

    &__title {
        grid-area: title;
    }

    &--image &__title {
        margin-bottom: 0;
        align-self: center;

        @include min(sm) {
            padding-bottom: 120px;
            align-self: flex-start;
        }
    }

    &__container {
        padding: 60px 0 40px;

        @include min(md) {
            padding: 120px 0 130px;
        }
    }

    &--image {
        padding-top: 55px;

        @include min(md) {
            padding-top: 120px;
        }
    }

    &__message {
        font-size: 2.8rem;
        line-height: $headerLineHeight;
    }

    &--image &__message + * {
        margin-top: 30px;
    }

    &__meta {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    &__quote-mark {
        margin-left: 15px;
        color: get-color('neutral', 0);

        &,
        svg {
            width: 63px;
            height: 40px;
        }
    }
}
