.l-2-10 {
    grid-column: content;
    display: grid;
    gap: var(--space, $gutter) var(--column, $gutter);

    @include min(sm) {
        grid-template-columns: minmax(0, 2fr) minmax(0, 10fr);
    }

    &[data-no-gutter] {
        gap: 0;
    }

    &[data-width='full-bleed'] {
        grid-column: full-bleed;
    }

    &[data-width='cover'] {
        grid-column: cover-page;
    }
}
