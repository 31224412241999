.l-wrapper {
    > *,
    [data-width='content'] {
        grid-column: content;
    }

    &--full-bleed,
    [data-width='full-bleed'] {
        grid-column: full-bleed;
    }

    &--full-page,
    [data-width='full-page'] {
        grid-column: full-page;
    }

    &--cover-page,
    [data-width='cover-page'] {
        grid-column: cover-page;
    }
}
