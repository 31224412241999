.u-text-icon {
    display: flex;
    align-items: center;
    font-size: $fontSize;

    svg {
        width: 24px;
        height: 24px;

        + * {
            margin-left: 10px;
        }
    }

    &__list {
        margin-left: -10px;
        width: calc(100% + 20px);

        > * {
            display: inline-flex;
            margin: 5px 10px;
        }
    }
}
