//====================
// z-index
//====================

$layerIncrement: 20;

$navLayer: $layerIncrement * 1;
$stickyLayer: $layerIncrement * 2;
$fixedLayer: $layerIncrement * 3;
$modalLayer: $layerIncrement * 5;
$popoverLayer: $layerIncrement * 6;
$tooltipLayer: $layerIncrement * 7;
