.c-signpost {
    --signpost-grad-opacity: 0.7;

    position: relative;

    &:after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60%;
        background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, var(--signpost-grad-opacity))
        );

        @include min(sm) {
            height: 40%;
        }
    }

    &__container {
        min-height: 450px;

        @include min(sm) {
            min-height: 90vh;
        }
    }

    &--no-header &__container {
        min-height: 280px;

        @include min(sm) {
            min-height: 90vh;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        z-index: 2;
        padding-bottom: 25px;

        @include min(sm) {
            padding-bottom: 100px;
        }
    }

    picture {
        @include max(sm) {
            transform: none !important;
        }
    }
}
