@keyframes ctaBreakSlideIn {
    0% {
        transform: translateX(-80%);
        opacity: 0;
    }

    100% {
        transform: none;
        opacity: 1;
    }
}

.c-cta-break {
    --transition-duration: 950ms;

    @media (prefers-reduced-motion: reduce) {
        --transition-duration: 1ms;
    }

    body.editmode & {
        --transition-duration: 1ms;
    }

    display: block;
    position: relative;
    overflow: hidden;
    padding-top: 80px;
    padding-bottom: 75px;

    h2,
    p {
        transition: color var(--transition-duration) $easeOutExpo;
    }

    &,
    &__content,
    &__button,
    body.loaded &[data-scroll-anim-active],
    body.loaded &[data-scroll-anim-active] &__content,
    body.loaded &[data-scroll-anim-active] &__button {
        @include min(sm) {
            animation-name: ctaBreakSlideIn;
            animation-timing-function: var(--scroll-ease);
            animation-duration: var(--scroll-duration);
            animation-fill-mode: forwards;
        }
    }

    body.loaded &[data-scroll-anim-active] &__content {
        @include min(sm) {
            animation-delay: 120ms;
        }
    }

    body.loaded &[data-scroll-anim-active] &__button {
        @include min(sm) {
            animation-delay: 240ms;
        }
    }

    body.loaded &,
    body.loaded &__content,
    body.loaded &__button {
        @include min(sm) {
            transform: translateX(-80%);
            opacity: 0;
            animation: none;
        }
    }

    @media (prefers-reduced-motion: reduce) {
        &,
        &__content,
        &__button {
            animation: none !important;
            transform: none !important;
            opacity: 1 !important;
        }
    }

    body.editmode &,
    body.editmode &__content,
    body.editmode &__button {
        animation: none !important;
        transform: none !important;
        opacity: 1 !important;
    }

    &[data-hover-active] {
        color: get-color('neutral', 100);
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translateY(-100%);
        transition: transform var(--transition-duration) $easeOutExpo;
    }

    &[data-hover-active] &__background {
        transform: none;
    }

    &__button {
        align-self: flex-end;
    }

    &__container {
        position: relative;
        z-index: 1;
    }

    .u-header-line:before {
        transition: background-color var(--transition-duration) $easeOutExpo;
    }

    &[data-hover-active] .u-header-line:before {
        background-color: get-color('tertiary', 50);
    }

    &[data-hover-active] h2 {
        color: currentColor !important;
    }

    .u-btn:before {
        background-color: #{get-color('neutral', 0)};
    }

    .u-btn:after {
        color: get-color('tertiary', 50);
    }

    &[data-hover-active] .u-btn {
        --color: #{get-color('tertiary', 50)};
        --font-color: #{get-color('neutral', 100)};
    }

    &[data-hover-active] .u-btn:hover:before {
        background-color: var(--color);
    }

    &[data-hover-active] .u-btn:hover:after {
        color: get-color('neutral', 0);
    }

    &[data-hover-loaded] .u-btn:not(:disabled):hover {
        transform: none;
    }
}
