.l-halves {
    grid-column: content;
    display: grid;
    gap: var(--space, $gutter) var(--column, $gutter);

    @include min(sm) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &[data-no-gutter] {
        gap: 0;
    }

    &[data-width='full-bleed'] {
        grid-column: full-bleed;
    }

    &[data-width='cover'] {
        grid-column: cover-page;
    }

    &[data-mobile] {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.l-halves-left-bleed,
.l-halves-right-bleed {
    grid-column: cover-page;

    > * {
        grid-column: content;

        @include min(sm) {
            &:first-child {
                margin-right: $gutter / 2;
            }

            &:last-child {
                margin-left: $gutter / 2;
            }
        }
    }
}

.l-halves-left-bleed > * {
    &:first-child {
        @include min(sm) {
            grid-column: full-bleed / half;
        }
    }

    &:last-child {
        @include min(sm) {
            grid-column: half2 / content;
        }
    }
}

.l-halves-right-bleed > * {
    &:first-child {
        @include min(sm) {
            grid-column: content / half;
        }
    }

    &:last-child {
        @include min(sm) {
            grid-column: half2 / full-bleed;
        }
    }
}
