body {
    font-family: $fontFamily;
    font-size: unit(strip-unit($fontSize), em);
    font-weight: $fontWeight;
    line-height: $lineHeight;
    color: get-color('fontColor');
    background-color: get-color('backgroundColor');
    font-kerning: normal;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    width: 100%;

    @include min(md) {
        font-size: unit(strip-unit($mdFontSize), em);
    }

    &[data-lock] {
        overflow: hidden;
    }
}
