.c-sub-service-listing {
    padding-top: 90px;
    padding-bottom: 60px;

    @include min(sm) {
        padding-top: 200px;
        padding-bottom: 180px;
    }

    *:not([class*='h-background']) + & {
        padding-top: 0;
    }

    &__image {
        display: block;

        + * {
            margin-top: 48px;
        }

        .u-media {
            --image-width: 640;
            --image-height: 353;
        }
    }

    &__container {
        gap: 100px 40px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 440px;
        width: 100%;
        flex-grow: 1;

        > *:nth-last-child(2) {
            margin-bottom: 20px;
        }

        .u-btn {
            margin-top: auto;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__item[data-hover-active] img {
        transform: scale(1.1);
    }

    &__item[data-hover-loaded] img {
        --transition-duration: #{$userActive};
        transition: transform var(--transition-duration) $easeOut;
        transform-origin: center;
    }

    &__intro {
        + * {
            margin-top: 90px;

            @include min(sm) {
                margin-top: 200px;
            }
        }
    }
}
