.l-wrapper,
.l-halves-left-bleed,
.l-halves-right-bleed {
    display: grid;
    gap: var(--space, $gutter) 0;
    grid-template-columns:
        [cover-page-start]
        $pageGutter
        [full-page-start]
        1fr
        [full-bleed-start content-start half-start]
        minmax(0, $gridInnerWidth)
        [half-end half2-start]
        minmax(0, $gridInnerWidth)
        [content-end half2-end full-bleed-end]
        1fr
        [full-page-end]
        $pageGutter
        [cover-page-end];

    @include min($gridBreakpoint) {
        grid-template-columns:
            [cover-page-start]
            $pageGutter
            [full-page-start]
            1fr
            [full-bleed-start]
            minmax(0, $gridOuterWidth)
            [content-start half-start]
            $gridInnerWidth
            [half-end half2-start]
            $gridInnerWidth
            [content-end half2-end]
            minmax(0, $gridOuterWidth)
            [full-bleed-end]
            1fr
            [full-page-end]
            $pageGutter
            [cover-page-end];
    }
}

$thirdsGridInnerWidth: $contentWidth / 3;

.l-third-two-thirds-right-bleed {
    display: grid;
    gap: var(--space, $gutter) 0;
    grid-template-columns:
        [cover-page-start]
        $pageGutter
        [full-page-start]
        1fr
        [full-bleed-start content-start third-start]
        minmax(0, $thirdsGridInnerWidth)
        [third-end third2-start]
        minmax(0, $thirdsGridInnerWidth)
        [third2-end third3-start]
        minmax(0, $thirdsGridInnerWidth)
        [content-end third3-end full-bleed-end]
        1fr
        [full-page-end]
        $pageGutter
        [cover-page-end];

    @include min($gridBreakpoint) {
        grid-template-columns:
            [cover-page-start]
            $pageGutter
            [full-page-start]
            1fr
            [full-bleed-start]
            minmax(0, $gridOuterWidth)
            [content-start third-start]
            $thirdsGridInnerWidth
            [third-end third2-start]
            $thirdsGridInnerWidth
            [third2-end third3-start]
            $thirdsGridInnerWidth
            [content-end third3-end]
            minmax(0, $gridOuterWidth)
            [full-bleed-end]
            1fr
            [full-page-end]
            $pageGutter
            [cover-page-end];
    }
}
