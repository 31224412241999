@keyframes gradFade {
    from {
        opacity: 100%;
    }

    to {
        opacity: 0;
    }
}

.c-global-popup {
    #exludeUrl {
        display: none;
    }
    @include max(sm) {
        &[open] &__aside {
            flex-direction: row-reverse;
            align-items: center;
            padding: 25px 0;
            flex: 1;
        }

        &[open] &__close {
            top: 60px;
            right: 40px;
            z-index: 9999;
        }

        &[open] &__form {
            flex-direction: column;
            justify-content: space-evenly;
            display: flex;

            .u-form__group + .u-form__group {
                margin-top: 30px;
            }

            .u-form__group + .u-btn {
                margin-top: 30px;
            }
        }

        &[open] &__content {
            padding-bottom: 35px;
        }
    }

    --trans-duration: #{$userInactive};

    position: fixed;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 940px;
    width: 100%;
    border: 0;
    padding: 0;
    opacity: 0;
    pointer-events: none;
    background-color: #fff;
    transform: translateY(50px);
    transition: opacity var(--trans-duration) $easeOut, transform var(--trans-duration) $easeOut;
    @include max(sm) {
        max-width: 100%;
        margin: 23px 15px 23px 15px;
        height: 100%;
        width: unset;
    }

    &[open] {
        --trans-duration: #{$userActive};
        display: block;
        opacity: 1;
        pointer-events: auto;
        transform: none;
        overflow: visible;
    }

    &::backdrop {
        background-color: rgba(245, 245, 244, 0.1);
    }

    &__wrap {
        display: flex;
        height: 400px;
    }

    &[open] &__wrap {
        @include max(sm) {
            flex-direction: column;
        }
    }
    &__flashTag {
        text-wrap: nowrap;
        display: flex;
        width: 145px;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 16px;
        background: #e7bb41;
        margin-bottom: 65px;
        color: var(--primary-raisin-black, #1c1f2a);
        font-family: Gilroy;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.96px;
        text-transform: uppercase;
        @include max(sm) {
            margin-bottom: 24px;        
        }
        &__hidden{
            background:unset;
            text-wrap: nowrap;
            display: flex;
            width: 145px;
            padding: 8px 24px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 16px;
            @include max(sm) {
                margin-bottom: 45px;        
            }
        }
    }

    &__title {
        margin-bottom: 24px;

        color: var(--primary-raisin-black, #1c1f2a);
        font-family: Gilroy;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        align-self: stretch;
        line-height: normal;
        @include max(sm) {
            font-size: 32px;
        }
    }
    &__body {
        margin-bottom: auto;
        color: var(--primary-raisin-black, #1c1f2a);
        font-family: Gilroy;
        font-size: 18px;
        font-style: normal;
        align-self: stretch;
        font-weight: 400;
        line-height: 28px;
        @include max(sm) {
            margin-bottom: 24px;
        }
    }

    &[class*='h-text'] &__title,
    &[class*='h-text'] &__body {
        color: inherit;
    }

    &__button {
        float: left;
    }
    &__aside {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 0 0 220px;
        padding: 93px 26px 50px;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        > * {
            z-index: 1;
        }
    }

    &__logo {
        position: relative;
        width: 163px;
        height: 55px;
        margin: 0 auto;
        color: get-color(primary, 0);
        padding-bottom: 25px;
        svg {
            width: 163px;
            height: 55px;
        }
    }

    &__escape {
        color: get-color('neutral', 100);
        transform-style: preserve-3d;
        background-color: #000;
        border-radius: 50%;
        width: 118px;
        height: 118px;
        margin: 0 auto;
        transition: transform 650ms $easeOutCubic;

        &.is-active {
            transition-duration: 900ms;
            transform: rotateY(180deg);
        }

        &-front,
        &-back {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            backface-visibility: hidden;
            transform: translate(-50%, -50%);

            svg {
                width: 100%;
                height: 100%;
            }
        }

        &-back {
            transform: translate(-50%, -50%) rotateY(180deg);
            width: 50px;
            height: 40px;
        }
    }

    &__form {
        position: relative;
        overflow: hidden;
        flex: 1 1 100%;
        padding: 24px 15px;

        @include min(sm) {
            padding: 72px 95px;
        }

        .u-form__group + .u-form__group {
            margin-top: 64px;
        }

        .u-form__group + .u-btn {
            margin-top: 82px;

            &:hover:after {
                color: var(--neutral-100);
            }
        }
    }

    &__content {
        font-size: 2rem;
        padding: 40px;
        width: 55%;
        display: flex;
        flex-direction: column;
        @include max(sm) {
            width: 100%;
            padding: 16px;
        }
    }

    &__close {
        border-radius: 30px;
        background-color: #fff !important;
        outline: unset !important;
        outline-offset: unset !important;
        position: absolute;
        top: -30px;
        right: 25px;
        display: flex;
        width: 48px;
        height: 48px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        color: black;
        background-color: transparent;
        transform: translate(50%, -100%);
        &:hover {
            background-color: black !important;
            color: #fff !important;
        }
        .u-hamburger {
            --burger-size: 35px;
        }
    }
    &__image {
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        width: 45%;
        picture {
            display: flex;
            width: 100%;
            height: 100%;
        }
        @include max(sm) {
            width: 100%;
        }
    }
    &__success {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translateX(100%);
        transition: transform $userActive $easeOut;
        background-color: #fff;
        z-index: 1;
        display: flex;
    }

    &__success &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 72px 95px;
        flex-grow: 1;

        .u-btn {
            margin-top: auto;
        }
    }

    &__form.hide-form &__success {
        transform: none;
    }
}
