.u-tick-content {
    position: relative;
    padding-left: 60px;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 36px;
        height: 36px;
        background-image: url(../images/tick.svg);
        background-repeat: no-repeat;
        background-size: 100%;
    }
}
