.c-accordion {
    position: relative;
    background-color: transparent;
    text-align: left;
    width: 100%;
    transition: color $userInactive $easeOut, background-color $userInactive $easeOut;

    &[data-active] {
        background-color: get-color('neutral', '75');
        transition: color $userActive $easeOut, background-color $userActive $easeOut;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 2px;
        background-color: get-color('primary', '50');
        opacity: 0;
        transition: opacity $userInactive $easeOut;
    }

    &[data-active]:before {
        opacity: 1;
        transition-duration: $userActive;
    }

    &__list {
        margin-top: 32px;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

        > * + * {
            border-top: 1px solid get-color('neutral', '50');
        }
    }

    h2 + * {
        margin-top: 0;
    }

    &__title {
        width: 100%;
        text-align: left;
        font-weight: 700;
        font-size: 2rem;
        cursor: pointer;
        background-color: transparent;

        &-wrap {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }

        span {
            display: block;
            padding: 32px;
        }
    }

    &:hover &__title,
    &[data-active] &__title {
        color: get-color('primary', '50');
    }

    &__icon {
        position: relative;
        width: 36px;
        height: 36px;
        margin-left: 32px;
        margin-right: 32px;
        border-radius: 50%;
        border: 2px solid get-color('neutral', '50');
        outline: none;
        transition: color $userInactive $easeOut, background-color $userInactive $easeOut,
            border-color $userInactive $easeOut;

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 16px;
            height: 16px;
            transform: translate(-50%, -50%);
        }
    }

    &__title:focus &__icon,
    &__title:hover &__icon,
    &[data-active] &__icon {
        background-color: get-color('primary', '50');
        border-color: get-color('primary', '50');
        color: get-color('neutral', '100');
        outline: none;
        transition: color $userActive $easeOut, background-color $userActive $easeOut,
            border-color $userActive $easeOut;
    }

    &[data-active] &__icon svg {
        transform: translate(-50%, -50%) rotate(180deg);
    }

    &__container {
        backface-visibility: hidden;
        transform: translate3d(0, 0, 0);
        height: 0;
        will-change: height;
        overflow: hidden;
        transition: height $userInactive $easeOut;
    }

    &[data-active] &__container {
        height: auto;
        transition: height $userActive $easeOut;
    }

    &__content {
        padding: 0 32px 32px;
    }
}
