//====================
// Visually hidden
//====================
// visually hides content, while leaving it accessible to voice over users

@mixin visually-hidden() {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}
