@keyframes headerLine {
    0% {
        transform: scaleX(0);
    }

    100% {
        transform: none;
    }
}

.u-header-line,
h2 {
    --line-height: 6px;

    position: relative;
    padding-top: calc(var(--line-height) + #{space('700')});

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 140px;
        height: var(--line-height);
        background-color: currentColor;
        transform-origin: left;
    }

    &[data-size='small'] {
        --line-height: 2px;

        padding-top: 0;

        &:before {
            width: 68px;
        }
    }

    &:before,
    body.loaded &[data-scroll-anim-active]:before {
        transform: scaleX(0);
        animation-name: headerLine;
        animation-timing-function: var(--scroll-ease);
        animation-duration: var(--scroll-duration);
        animation-fill-mode: forwards;
    }

    body:not(.loaded) &:before {
        animation-delay: var(--scroll-load-delay);
    }

    body.loaded &:before {
        transform: scale(0);
        animation: none;
    }

    @media (prefers-reduced-motion: reduce) {
        &:before {
            animation: none !important;
            transform: none !important;
        }
    }

    body.editmode &:before {
        animation: none !important;
        transform: none !important;
    }

    &[data-color]:before {
        background-color: var(--header-line-color);
    }

    > .u-small-header {
        padding-top: 10px;
    }
}
