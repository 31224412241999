//====================
//  Design token tools
//====================

// create the css custom var name
// .example {
//     background-color: get-color('primary', '50');
// }

// to use a theme you add a data attribute to your html,
// this can be on the body or any other html like a section
//
// <body data-theme="demo"></body>
//
// data-theme="{(theme name, key used in $themes)}"

@function get-color($color, $variant: '') {
    @if (map-has-key($namedColors, $color)) {
        $colorList: map-get($namedColors, $color);
        $color: nth($colorList, 1);
        $variant: nth($colorList, 2);

        @return var(--#{$color}-#{$variant});
    }

    @return var(--#{$color}-#{$variant});
}

// helper function for ColorVars
@mixin _printOutColorVars($themeMap) {
    @each $colorName, $colorVariants in $themeMap {
        @each $variantName, $color in $colorVariants {
            --#{$colorName}-#{$variantName}: #{$color};
        }
    }
}

// sets up the css custom vars in root with theme classes
// used in global vars
@mixin colorVars {
    @each $themeName, $themeMap in $themes {
        @if $themeName == 'default' {
            @include _printOutColorVars($themeMap);
        } @else {
            [data-theme='#{$themeName}'] {
                @include _printOutColorVars($themeMap);
            }
        }
    }
}
