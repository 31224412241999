$commonBreakpointNames: map-keys($commonBreakpoints);
$sizeNames: map-keys($spaceScale);

.u-flow[data-space='0'] > *,
[class*='l-'][data-space='0'],
.u-flow.u-flow > [data-overwrite-space='0'] + * {
    --space: 0;
}

@each $commonBreakpointName in $commonBreakpointNames {
    .u-flow[data-space-#{$commonBreakpointName}='0'] > *,
    [class*='l-'][data-space-#{$commonBreakpointName}='0'],
    .u-flow.u-flow > [data-overwrite-space-#{$commonBreakpointName}='0'] + * {
        @include min($commonBreakpointName) {
            --space: 0;
        }
    }
}

@each $sizeName in $sizeNames {
    .u-flow.u-flow > [data-overwrite-space='#{$sizeName}'][data-overwrite-space='#{$sizeName}'] + *,
    .u-flow[data-space='#{$sizeName}'] > *,
    [class*='l-'][data-space='#{$sizeName}'] {
        --space: var(--space-#{$sizeName});
    }

    @each $commonBreakpointName in $commonBreakpointNames {
        .u-flow[data-space-#{$commonBreakpointName}='#{$sizeName}'] > *,
        [class*='l-'][data-space-#{$commonBreakpointName}='#{$sizeName}'],
        .u-flow.u-flow > [data-overwrite-space-#{$commonBreakpointName}='#{$sizeName}'] + * {
            @include min($commonBreakpointName) {
                --space: var(--space-#{$sizeName});
            }
        }
    }
}

$blockSizeNames: map-keys($blockSpaceScale);

@each $blockSizeName in $blockSizeNames {
    .u-flow[data-space='#{$blockSizeName}'][data-space-type='block'] > *,
    [class*='l-'][data-space='#{$blockSizeName}'][data-space-type='block'],
    .u-flow.u-flow
        > [data-overwrite-space='#{$blockSizeName}'][data-overwrite-space-type='block']
        + * {
        --space: var(--block-space-#{$blockSizeName});
    }

    @each $commonBreakpointName in $commonBreakpointNames {
        .u-flow[data-space-#{$commonBreakpointName}='#{$blockSizeName}'][data-space-type='block']
            > *,
        [class*='l-'][data-space-#{$commonBreakpointName}='#{$blockSizeName}'][data-space-type='block'],
        .u-flow.u-flow
            > [data-overwrite-space-#{$commonBreakpointName}='#{$blockSizeName}'][data-space-type='block']
            + * {
            @include min($commonBreakpointName) {
                --space: var(--block-space-#{$blockSizeName});
            }
        }
    }
}
