.u-flow {
    > * + * {
        margin-top: var(--space);
    }

    > [class*='h-background'] + [class*='h-background'] {
        margin-top: 0;
    }

    .cke_editable_inline > * + * {
        margin-top: var(--space-800);
    }
}
