.u-media-bg {
    --video-width: 16;
    --video-height: 9;

    grid-column: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;

    &__container {
        position: relative;

        > * {
            z-index: 1;
        }
    }

    &:after,
    &__inner,
    &__parallax-wrap,
    img,
    picture,
    iframe,
    video {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__inner {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &:after {
        content: '';
        background-color: get-color('neutral', '0');
        opacity: var(--media-bg-opacity, 0.85);
    }

    img {
        object-fit: cover;
    }

    iframe,
    video {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        width: calc(100 * var(--video-width) / var(--video-height) * 1vh);
        min-width: 100%;
        min-height: calc(100 * var(--video-height) / var(--video-width) * 1vw);
    }
}
