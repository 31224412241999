.c-locations {
    &[class*='h-background'] {
        padding-top: 80px;
        padding-bottom: 100px;

        @include min(sm) {
            padding-top: 200px;
            padding-bottom: 200px;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .u-media {
            --image-width: 380;
            --image-height: 244;
            width: 100%;

            @include min(sm) {
                max-width: 380px;
            }
        }

        > * {
            &:last-child {
                margin-top: auto;
            }

            &:nth-last-child(2) {
                padding-bottom: 40px;
            }
        }

        h3 + p {
            margin-top: 30px !important;
        }
    }
}
