.u-form-label {
    position: relative;
    width: 100%;

    textarea,
    input,
    select {
        padding: 25px 0 15px;
    }

    label {
        position: absolute;
        top: 1.8em;
        left: 0;
        pointer-events: none;
        transition: transform $userInactive $easeOut;
        z-index: 1;
    }

    &:focus-within label,
    &[data-active] label {
        transform: scale(0.75) translateY(-1.8em);
        transform-origin: left;
        transition-duration: $userActive;
    }

    textarea {
        height: 90px;
        will-change: height;
        transition: height $userInactive $easeOut;
    }

    &:focus-within textarea,
    &[data-active] textarea {
        height: 250px;
        transition-duration: $userInactive;
    }
}
