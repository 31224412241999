.l-quarters {
    grid-column: full-bleed;
    display: grid;
    gap: var(--space, $gutter) var(--column, $gutter);

    @include min(sm) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include min(md) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    &--clients,
    &--mobile-halves {
        gap: var(--space, $gutter) 0;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        @include min(md) {
            gap: var(--space, $gutter) var(--column, $gutter);
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }
}

.l-quarter-3-quarters {
    grid-column: content;
    display: grid;
    gap: var(--space, $gutter) var(--column, $gutter);

    @include min(sm) {
        grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
    }

    @include min(md) {
        grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
    }
}

.l-3-quarters {
    grid-column: content;
    display: grid;
    gap: var(--space, $gutter) var(--column, $gutter);

    @include min(sm) {
        grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    }

    @include min(md) {
        grid-template-columns: minmax(0, 3fr) minmax(0, 1fr);
    }
}
