$contentWidth: 94rem;
$bleedWidth: 132rem;
$gutter: 2rem;
$pageGutter: 1.5rem;

$logoSize: 55px;
$logoSize\@sm: 90px;
$logoSizeLg: 80px;
$logoSizeLg\@sm: 165px;

$gridOuterWidth: ($bleedWidth - $contentWidth) / 2;
$gridInnerWidth: $contentWidth / 2;
$gridBreakpoint: 94 * 10 + 1.5 * 20;
