$mobileBreakpoint: 855;

.c-filter {
    font-size: $fontSize;
    scroll-margin-top: 160px;

    body.loggedin & {
        scroll-margin-top: 160px + 45px;
    }

    &__container {
        position: relative;

        &:before {
            content: '';
            grid-column: full-bleed;
            grid-row: 1;
            background-color: get-color('neutral', '0');
        }
    }

    &__content {
        grid-column: content;
        grid-row: 1;
        padding: 30px 10px;

        @include min(md) {
            padding: 15px 5px;
            display: flex;
            align-items: center;
        }

        li + li {
            margin-top: space(300);

            @include min(sm) {
                margin-top: 0;
            }
        }
    }

    span {
        display: block;
        margin-right: 30px;
        margin-bottom: 10px;
        font-size: 1.6rem;
        padding-left: 5px;

        @include min(md) {
            margin-bottom: 0;
        }
    }

    ul {
        margin-left: auto;

        @include min($mobileBreakpoint) {
            display: flex;
            align-items: center;
        }

        a {
            padding: 5px;
            font-size: 1.7rem;
        }

        li + li {
            @include min($mobileBreakpoint) {
                margin-left: 12px;
            }
        }
    }
}
