.c-app-screens {
    padding-top: 20px;
    overflow: hidden;

    @include min(sm) {
        padding-top: 100px;
    }

    &__container {
        padding-bottom: 120px;
    }

    &__track {
        grid-column: full-page;
        display: flex;
        // transition: transform var(--duration, 10s) linear;
        transform: translateX(calc(var(--scroll-up) * var(--distance) * -1));

        li {
            --width: clamp(120px, 30vw, 380px);

            flex: 0 0 var(--width);
            max-width: var(--width);
            width: 100%;
            box-shadow: -30px 22px 30px rgba(0, 0, 0, 0.15);

            + * {
                margin-left: 20px;

                @include min(sm) {
                    margin-left: 40px;
                }
            }

            &:nth-child(2n + 1) {
                transform: translateY(-20px);

                @include min(sm) {
                    transform: translateY(-100px);
                }
            }

            &:nth-child(2n) {
                transform: translateY(70px);
            }
        }
    }
}
