$viewportColor: var(--h-background-color, #{get-color('neutral', 100)});

.c-ven-circles {
    --item-count: 1;
    --circle-1-position: 50%; // [50%, 12.5%]
    --circle-3-position: -50%; // [-50%, -12.5%]
    --clip-top-position: 50%; // [50%, 13%]
    --clip-bot-position: 50%; // [50%, 87.5%]

    position: relative;
    padding-top: 30px;

    body:not(.editmode) & + * {
        @include min(sm) {
            padding-top: var(--space);
            position: relative;
            z-index: 1;

            &:not([class*='h-background']) {
                background-color: get-color('backgroundColor');
            }
        }
    }

    &__device-wrap {
        @include min(sm) {
            grid-column: 2 / 3;
            grid-row: 1 / span calc(var(--item-count) + 1);
        }

        &:before {
            display: none;
        }
    }

    &__sticky {
        position: sticky;
        top: 150px;
        z-index: 1;
    }

    &__device {
        position: relative;
        width: 244px;
        margin: 0 auto;
        color: get-color('neutral', 0);
    }

    &__circle {
        position: relative;

        p {
            font-weight: 700;
            font-size: 2.4rem;
            width: 100%;
            text-align: center;
        }

        &:before {
            content: '';
            display: block;
            padding-bottom: 100%;
        }

        &:nth-child(1) {
            transform: translateY(var(--circle-1-position));
        }

        &:nth-child(2) {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            transform: translateY(-50%);
            z-index: 1;
            clip-path: circle(50% at center var(--clip-top-position));
        }

        &:nth-child(3) {
            transform: translateY(var(--circle-3-position));
        }
    }

    &__circle:nth-child(2) &__shape {
        clip-path: circle(50% at center var(--clip-bot-position));
    }

    &__shape {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 85%;
        height: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
    }

    &__stat {
        text-align: center;

        @include min(sm) {
            text-align: left;
        }

        p {
            font-size: var(--font-size);
        }

        @include min(sm) {
            &:nth-child(even) {
                grid-column: 3 / 4;
            }

            @for $i from 1 through 15 {
                &:nth-child(#{$i}) {
                    grid-row: #{$i} / #{$i + 1};
                }
            }
        }
    }

    &__container {
        position: relative;
        padding-bottom: 60px;

        @include min(sm) {
            padding-top: 120px;
            padding-bottom: 120px;
        }

        &:before {
            height: 550px;
            grid-row: 1 / 2;

            @include min(sm) {
                content: '';
                display: block;
            }
        }

        > * + * {
            margin-top: block-space(300);

            @include min(sm) {
                margin-top: 0;
            }
        }
    }

    &__viewport {
        position: absolute;
        top: -120px;
        left: 50%;
        width: 100vw;
        height: 720px;
        transform: translateX(-50%);
        pointer-events: none;
        display: none;

        @include min(sm) {
            display: block;
        }

        div {
            body.editmode & {
                display: none;
            }

            &:before {
                content: '';
                position: absolute;
                bottom: 100%;
                height: 30px;
                width: 100vw;
                background-color: #{$viewportColor};
            }

            &:after {
                content: '';
                position: absolute;
                top: 100%;
                height: calc(100vh - 720px);
                min-height: 500px;
                width: 100vw;
                background-color: #{$viewportColor};
            }
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            height: 15%;
        }

        &:before {
            top: 0;
            background-image: linear-gradient(to top, rgba(#1c1f2a, 0), #{$viewportColor});
        }

        &:after {
            bottom: 0;
            background-image: linear-gradient(to bottom, rgba(#1c1f2a, 0), #{$viewportColor});
        }
    }

    &__logo {
        position: relative;
        margin-bottom: 41px;

        @include min(sm) {
            margin-bottom: 61px;
        }

        &-wrap {
            position: relative;
            width: 160px;
            height: 60px;
            margin: 0 auto;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: -25px;
            left: 0;
            height: 1px;
            width: 100%;
            background-color: currentColor;

            @include min(sm) {
                bottom: -30px;
            }
        }

        svg,
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            color: get-color('secondary', 50);
        }

        img {
            object-fit: contain;
        }
    }

    &__stat &__stat-value {
        font-size: var(--h2);
    }
}
