.c-events-listing {
    &__date {
        margin-top: var(--space-700) !important;

        + * {
            margin-top: 17px;
        }
    }

    .u-text-icon__list {
        margin-top: 18px;
    }

    .u-btn {
        margin-top: 30px;
    }
}
