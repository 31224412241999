.c-footer {
    font-size: $fontSize;

    &__bar {
        padding: 40px 0 48px;
        font-size: $headerSmall;
        text-align: center;

        @include min(sm) {
            text-align: left;
            padding: 40px 0;
        }

        ul:not(.c-social) {
            margin-left: -15px;

            @include min(sm) {
                display: flex;
                flex-wrap: wrap;
            }

            > * {
                margin: 10px 15px;

                + * {
                    margin-top: 20px;

                    @include min(sm) {
                        margin-top: 10px;
                    }
                }
            }

            + ul:not(.c-social) {
                margin-top: 20px;

                @include min(sm) {
                    margin-top: 0;
                }
            }
        }
    }

    &__links {
        @include min(sm) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
        }

        &-info {
            @include max(sm) {
                margin-bottom: 20px;
            }

            font-size: $headerSmall;
            flex-basis: 100%;
        }
    }

    &__container {
        grid-column: content;
        padding: 50px 0;

        @include min(sm) {
            padding: 100px 0;
        }
    }

    &__contact {
        display: flex;
        flex-direction: column;

        @include min(sm) {
            padding-right: 20px;
        }

        ul {
            margin-top: auto;
            padding-top: var(--space);
        }
    }

    &__spacer {
        display: none;

        @include min(sm) {
            display: block;
        }
    }

    &__accreditations {
        display: flex;
        justify-content: space-between;
        padding-bottom: 25px;

        @include max(sm) {
            flex-wrap: wrap;
        }

        > * {
            @include max(md) {
                width: 50%;
            }

            @include min(sm) {
                display: flex;
                justify-content: flex-start;

                &:not(:first-child):not(:last-child) {
                    justify-content: center;
                }

                &:last-child {
                    justify-content: flex-end;
                }
            }

            @include min(md) {
                min-width: 170px;
            }
        }

        > * + * {
            @include min(md) {
                margin-left: 10px;
            }
        }

        img {
            height: 110px;
            width: auto;
        }

        &--drum-logo {
            display: flex;
            align-items: center;
            background-color: #fff;
            border-radius: 5px;
            padding: 20px 12px;

            img {
                height: auto;

                @include min(sm) {
                    height: 50px;
                }
            }
        }
        &--iso-logo {
            display: flex;
            align-items: center;
            background-color: #fff;
            border-radius: 5px;
            img {
                height: 110px;
                width: auto;
            }
        }
    }

    &__register {
        align-items: center;
    }

    .c-social {
        @include max(sm) {
            padding-top: 5px;
            margin-top: 35px;
            justify-content: center;
        }

        &__link {
            height: 48px;
        }
    }

    &__newsletter {
        padding-top: 78px;
        border-top: 1px solid var(--neutral-100);

        img {
            width: 225px;
            height: 75px;
        }
    }

    &__newsletter-content {
        margin-top: 30px;

        @include min(md) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        p {
            font-size: 2rem;
            font-weight: 700;
            line-height: 1.333;
        }
    }

    &__newsletter-btn {
        --trans-durations: #{$userInactive};

        background-color: transparent;
        text-decoration: none;
        border-radius: 28px;
        border: 2px solid var(--neutral-100);
        font-size: 2rem;
        font-weight: 700;
        line-height: $headerLineHeight;
        padding: 10px 26px;
        min-width: 184px;
        color: var(--neutral-100);
        transition: background-color var(--trans-durations) $easeOut,
            color var(--trans-durations) $easeOut;

        @include min(sm) {
            margin-left: 75px;
        }

        @include max(md) {
            margin-top: 20px;
        }

        &:hover {
            --trans-durations: #{$userActive};

            color: var(--neutral-0);
            background-color: var(--neutral-100);
        }
    }
}
