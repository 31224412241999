@keyframes moveUp {
    0% {
        transform: translateY(var(--distance));
    }

    100% {
        transform: none;
    }
}

.u-scroll-anim {
    --distance: 0;

    @include min(sm) {
        --distance: 40px;
    }

    &,
    body.loaded &[data-scroll-anim-active] {
        transform: translateY(var(--distance));
        animation-name: moveUp;
        animation-timing-function: var(--scroll-ease);
        animation-duration: var(--scroll-duration);
        animation-fill-mode: forwards;
    }

    body:not(.loaded) & {
        animation-delay: var(--scroll-load-delay);
    }

    body.loaded & {
        transform: translateY(var(--distance));
        animation: none;
    }

    @media (prefers-reduced-motion: reduce) {
        animation: none !important;
        transform: none !important;
    }

    body.editmode & {
        animation: none !important;
        transform: none !important;
    }
}
