.u-limit-content {
    @include min(md) {
        max-width: 55ch;
    }

    &--insight {
        @include min(sm) {
            max-width: 55ch;
        }
    }

    &--half {
        @include min(md) {
            max-width: 50%;
        }
    }

    &--large {
        @include min(md) {
            max-width: 75%;
        }
    }

    &--hero {
        @include min(sm) {
            max-width: 45ch;
        }
    }

    &--hero-lg {
        @include min(sm) {
            max-width: 62ch;
        }
    }

    &--hero-sm {
        @include min(sm) {
            max-width: 35ch;
        }
    }

    &--sm {
        @include min(sm) {
            max-width: 40ch;
        }
    }

    &--xs {
        @include min(sm) {
            max-width: 20ch;
        }
    }
}
