.h-text {
    &--lead {
        font-size: $leadFontSize;
        line-height: $headerLineHeight;
    }

    &--truncate {
        @include text-truncate;
    }

    &--nowrap {
        white-space: nowrap !important;
    }

    &--lowercase {
        text-transform: lowercase !important;
    }

    &--uppercase {
        text-transform: uppercase !important;
    }

    &--center {
        text-align: center !important;
    }

    &--right {
        text-align: right !important;
    }

    &--left {
        text-align: left !important;
    }
}
