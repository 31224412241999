ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

ul[data-minimal] {
    li {
        position: relative;
        padding: 0.25em 0;

        ul {
            padding-top: 0.25em;
            margin-bottom: -0.25em;
        }
    }
}

ul:not([data-unstyled]):not([data-minimal]) {
    padding-inline-start: 1.25em;

    li {
        position: relative;
        padding: 0.25em 0;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0.75em;
            left: -1.25em;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: get-color('secondary', '50');
        }

        ul {
            padding-top: 0.25em;
            margin-bottom: -0.25em;
        }
    }

    &.u-list-small {
        font-size: 1.6rem;
    }
}

ol {
    counter-reset: orderedList;
    padding-inline-start: 0;

    ol {
        padding-inline-start: 1.2em;
        padding-top: 0.1em;
        margin-bottom: -0.1em;
    }

    li {
        padding: 0.1em 0;

        &:before {
            counter-increment: orderedList;
            content: counter(orderedList) '.';
            padding: 0 0.45em;
        }
    }
}
