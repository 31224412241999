//====================
// Unit tools
//====================
// tools to manipulate value units

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }

    @return $number;
}

@function unit($num, $unit) {
    @return strip-unit($num) + $unit;
}

@function px2em($px, $base-font-size: 16px) {
    @if unitless($px) {
        @warn "Assuming #{$px} to be in pixels, attempting to convert it into pixels.";

        @return mq-px2em($px * 1px, $base-font-size);
    } @else if unit($px) == em {
        @return $px;
    }

    @return ($px / $base-font-size) * 1em;
}
