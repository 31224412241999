.c-team-listing {
    text-align: center;
    font-size: $fontSize;

    &__image-wrap {
        position: relative;
        width: 106.66666666666667%;
        left: -3.333333%;

        + * {
            margin-top: block-space(200);
        }

        &:before {
            content: '';
            display: block;
            padding-bottom: 100%;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__circle {
        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 86%;
            height: 86%;
            background-color: get-color('tertiary', 50);
            border-radius: 50%;
            transform: translateX(-50%);
        }

        img {
            clip-path: circle(42.99% at 50% 57%);
        }

        + img,
        + picture img {
            clip-path: polygon(0 0, 100% 0, 100% 80%, 0 80%);
        }
    }

    &__description {
        line-height: $headerLineHeight;
    }

    &__name {
        font-size: var(--h3);
        font-weight: 700;
    }

    &__image[data-name='guy-nicholson'] &__circle + picture img {
        clip-path: polygon(0 0, 100% 0, 98.12% 60.63%, 31.88% 80.94%, 22.19% 98.44%, 5% 97.81%);
    }

    &__image[data-name='ian-mumford'] &__circle + picture img {
        clip-path: polygon(0 0, 100% 0, 100% 70%, 0 70%);
    }

    &__image[data-name='lucy-walker'] &__circle + picture img {
        clip-path: polygon(0 0, 100% 0, 100% 80%, 0 94%);
    }

    &__image[data-name='michael-slabbert'] &__circle + picture img {
        clip-path: polygon(0 0, 100% 0, 100% 96%, 50% 90%, 0 93%);
    }

    &__image[data-name='rob-jones'] &__circle + picture img {
        clip-path: polygon(0 0, 100% 0, 100% 80%, 0 60%);
    }

    &__image[data-name='tim-norris'] &__circle + picture img {
        clip-path: polygon(0 0, 100% 0, 100% 95%, 0 80%);
    }

    &__image[data-name='tom-hopkins'] &__circle + picture img {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 35%);
    }

    &__image[data-name='zoe-pedersen'] &__circle + picture img {
        clip-path: polygon(0 0, 100% 0, 100% 70%, 0 70%);
    }

    &__image[data-name='charlotte-street'] &__circle + picture img {
        clip-path: polygon(0 0, 100% 0, 100% 70%, 0 70%);
    }

    &__image[data-name='jemma-west'] &__circle + picture img {
        clip-path: polygon(0 0, 100% 0, 85% 95%, 0 75%);
    }

    &__image[data-name='nicky-chute'] &__circle + picture img {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 65%);
    }

    &__image[data-name='karina-waller'] &__circle + picture img {
        clip-path: polygon(0 0, 100% 0, 100% 60%, 0 80%);
    }

    &__image[data-name='karina-waller'] &__image-wrap {
        transform: scaleX(-1);
    }

    &__image[data-name='lauren-oxberry'] &__circle + picture img {
        clip-path: polygon(0 0, 100% 0, 100% 70%, 0 80%);
    }

    &__image[data-name='emily-hore-patrick'] &__circle + picture img {
        clip-path: polygon(0 0, 100% 0, 100% 55%, 0 80%);
    }

    &__image[data-name='jon-baverstock'] &__circle + picture img {
        clip-path: polygon(0 0, 100% 0, 70% 80%, 0 80%);
    }

    &__image[data-name='shannon-webb'] &__circle + picture img {
        clip-path: polygon(0 0, 100% 0, 70% 80%, 0 70%);
    }

    &__image[data-name='kayleigh-walker'] &__circle + picture img {
        clip-path: polygon(0px 0px, 100% 0px, 100% 90%, 0% 100%);
    }

    &__image[data-name='guy-spriggs'] &__circle + picture img {
        clip-path: polygon(0px 0px, 100% 0px, 100% 75%, 0px 80%);
    }

    &__image[data-name*='ellie-d'] &__circle + picture img {
        clip-path: polygon(0px 0px, 100% 0px, 100% 70%, 0px 100%);
    }

    &__image[data-name='laurianne-brophy'] &__circle + picture img {
        clip-path: polygon(0px 0px, 100% 0px, 100% 75%, 0px 0);
    }

    &__image[data-name='alex-spriggs'] &__circle + picture img {
        clip-path: polygon(0px 0px, 100% 0px, 100% 75%, 0px 0);
    }

    &__image[data-name*='kerri-fear'] &__circle + picture img,
    &__image[data-name*='shannon-valentine'] &__circle + picture img {
        clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
    }
}
