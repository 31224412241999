//====================
// Easing
//====================

// custom basic easeOut
$easeOut: cubic-bezier(0.3, 0.15, 0.18, 1);

// common easings
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeInOutCubic: cubic-bezier(0.65, 0, 0.35, 1);
