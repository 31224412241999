.c-outcomes {
    position: relative;
    padding-top: 60px;
    padding-bottom: 80px;

    @include min(sm) {
        padding-top: 120px + 175px;
        padding-bottom: 160px;
    }

    &[data-no-image] {
        padding-top: 60px;
    }

    &__background {
        position: absolute;
        top: 25px;
        left: 0;
        width: 100%;
        height: calc(100% - 25px);

        @include min(sm) {
            top: 175px;
            height: calc(100% - 175px);
        }

        + * {
            position: relative;
            z-index: 1;
        }
    }

    &[data-no-image] &__background {
        top: 0;
        height: 100%;
    }

    &__media {
        margin-top: -65px;

        @include min(sm) {
            margin-top: -120px - 175px;
        }
    }

    &[data-no-image] &__media {
        display: none;

        @include min(sm) {
            display: block;
        }
    }

    &__header {
        order: 1;

        @include min(sm) {
            order: 0;
        }
    }
}
