/* ===============================
=            Choices            =
=============================== */

$choices-font-size-md: 1.4rem;
$choices-font-size-sm: 1.2rem;
$choices-border-radius: 0;
$choices-border-radius-item: 0;
$choices-bg-color: get-color('neutral', 100);
$choices-bg-color-disabled: get-color('neutral', 75);
$choices-bg-color-dropdown: get-color('neutral', 75);
$choices-text-color: get-color('fontColor');
$choices-keyline-color: get-color('neutral', 50);
$choices-primary-color: get-color('primary', 50);
$choices-disabled-color: get-color('neutral', 50);
$choices-highlight-color: $choices-primary-color;
$choices-button-dimension: 8px;
$choices-button-offset: 8px;
$choices-icon-cross: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
$choices-icon-cross-inverse: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);

.choices {
    position: relative;
    border-bottom: 1px solid get-color('neutral', 0);

    &:focus {
        outline: none;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &[data-type*='select-one'] {
        cursor: pointer;
    }

    [hidden] {
        display: none !important;
    }

    &__inner {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        background-color: $choices-bg-color;
        padding: 0.85em 0;
        min-height: 42px;
        overflow: hidden;
    }

    &[data-type*='select-multiple'] &__inner {
        padding: 25px 0 4px;
        min-height: 67px;
    }

    &__item {
        cursor: default;

        &--selectable {
            cursor: pointer;
        }

        &--disabled {
            cursor: not-allowed;
            user-select: none;
            opacity: 0.5;
        }
    }

    &__list {
        font-size: $fontSize;
        margin: 0;
        padding-left: 0;
        list-style: none;

        &--single {
            display: inline-block;
            width: 100%;
        }

        &--multiple {
            display: inline;
        }

        &--dropdown {
            z-index: 2;
            position: absolute;
            width: 100%;
            background-color: $choices-bg-color-dropdown;
            top: 100%;
            margin-top: -1px;
            overflow: hidden;
            word-break: break-all;
            opacity: 0;
            pointer-events: none;

            &.is-active {
                opacity: 1;
                pointer-events: auto;
            }

            .is-flipped & {
                top: auto;
                bottom: 100%;
                margin-top: 0;
                margin-bottom: -1px;
            }
        }
    }

    &__list--single &__item {
        width: 100%;
    }

    &__list--multiple &__item {
        display: inline-block;
        vertical-align: middle;
        border-radius: 32px;
        padding: 4px 15px;
        font-size: var(--font-size);
        font-weight: 500;
        margin-right: 3px;
        margin-bottom: 3px;
        background-color: get-color('neutral', 0);
        color: get-color('neutral', 100);
        word-break: break-all;
        box-sizing: border-box;
        transition: background-color $userInactive $easeOut, color $userInactive $easeOut;

        &:hover {
            transition: background-color $userActive $easeOut, color $userActive $easeOut;
            background-color: get-color('primary', 50);
            color: get-color('neutral', 0);
        }
    }

    &__list--dropdown &__list {
        position: relative;
        max-height: 300px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        will-change: scroll-position;
    }

    &__list--dropdown &__item {
        position: relative;
        padding: 10px;
        font-size: $choices-font-size-md;
    }

    &__list--dropdown &__item--selectable {
        &.is-highlighted {
            background-color: get-color('neutral', 0);
            color: #fff;
        }
    }

    &__heading {
        font-size: $headerSmall;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        font-weight: 700;
        line-height: 1.2;
        padding: 10px;
        color: get-color('neutral', 0);
    }

    &__list--dropdown &__placeholder {
        min-height: 41px;
    }

    &__icon {
        pointer-events: none;
        position: absolute;
        top: 48%;
        right: 0;
        width: 16px;
        height: 16px;
        transform: translateY(-50%);

        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 16px;
            height: 16px;
        }
    }

    &.is-open &__icon {
        transform: translateY(-50%) rotate(180deg);
    }

    &[data-type*='select-multiple'] &__input {
        padding: 0;
        border-bottom: 0;
        display: inline-block;
        width: auto;
    }

    &__button {
        text-indent: -9999px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 0;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
    }

    &[data-type*='select-multiple'] &__button {
        position: relative;
        display: inline-block;
        margin: 0 -4px 0 8px;
        padding-left: 16px;
        background-image: $choices-icon-cross;
        background-size: 8px;
        width: 8px;
        line-height: 1;
        opacity: 0.75;
        border-radius: 0;
    }

    &[data-type*='select-multiple'] &__list--multiple &__item:hover &__button {
        filter: invert(1);
    }
}
