.u-list-icon {
    position: relative;

    svg {
        position: relative;
        z-index: 1;
        width: 80px;
        height: 80px;
        padding: 22px 15px;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 80px;
        height: 80px;
        background-color: get-color('tertiary', 50);
        border-radius: 50%;
    }
}
