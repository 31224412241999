.c-stats {
    font-size: $fontSize;

    > *:not(:last-child) {
        padding-bottom: 20px;
        border-bottom: 1px solid currentColor;
    }

    &[class*='l-'] {
        > * {
            border-bottom: 1px solid currentColor;
        }
    }

    &__title {
        font-size: var(--h1);
    }
}
