.c-logo {
    --progress: 0%;
    --cross-over: var(--progress);

    position: relative;
    transform-origin: top left;

    &,
    svg {
        width: $logoSize;
        height: $logoSize;

        @include min(sm) {
            width: $logoSize\@sm;
            height: $logoSize\@sm;
        }
    }

    &--25,
    &--25 svg {
        width: 120px;
        height: 55px;

        @include min(sm) {
            padding-top: 10px;
            width: 174px;
            height: 80px;
        }
    }

    &[data-hidden] {
        @include min(md) {
            opacity: 0;
            pointer-events: none;
        }
    }

    &[data-size='large'],
    &[data-size='large'] svg {
        width: $logoSizeLg;
        height: $logoSizeLg;

        @include min(sm) {
            width: $logoSizeLg\@sm;
            height: $logoSizeLg\@sm;
        }
    }

    svg {
        position: absolute;
        top: 0;
        left: 0;
    }

    &[data-animate] svg {
        transition: clip-path var(--scroll-duration) var(--scroll-ease);
    }

    &[data-active] {
        --progress: 100%;
    }

    &[data-scroll-dir='down'] {
        --cross-over: calc(100% - var(--progress));
    }

    &__dark,
    &[data-style='dark'][data-scroll-dir='down'] &__dark {
        color: get-color('neutral', '100');
        fill: get-color('neutral', '0');
        clip-path: polygon(0 0, 100% 0, 100% var(--cross-over), 0% var(--cross-over));
    }

    &[data-style='dark'] &__dark,
    &[data-scroll-dir='down'] &__dark {
        clip-path: polygon(0 100%, 100% 100%, 100% var(--cross-over), 0% var(--cross-over));
    }

    &__light,
    &[data-style='dark'][data-scroll-dir='down'] &__light {
        color: get-color('neutral', '0');
        fill: get-color('neutral', '100');
        clip-path: polygon(0 100%, 100% 100%, 100% var(--cross-over), 0% var(--cross-over));
    }

    &[data-style='dark'] &__light,
    &[data-scroll-dir='down'] &__light {
        clip-path: polygon(0 0, 100% 0, 100% var(--cross-over), 0% var(--cross-over));
    }

    &:before {
        display: none;
    }
}
