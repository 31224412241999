@keyframes enquireTitle {
    0% {
        transform: translateX(-105%);
    }

    100% {
        transform: none;
    }
}

@keyframes enquireContent {
    0% {
        opacity: 0;
        transform: translateY(80px);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

.c-enquire {
    &[class*='h-background'] &__content {
        padding-bottom: 90px;

        @include min(sm) {
            padding-top: 120px + 49px;
            padding-bottom: 180px;
        }
    }

    &[class*='h-background'] &__title-half {
        padding-bottom: 0;
        padding-top: 90px;

        @include min(sm) {
            padding-top: 120px + 49px;
            padding-bottom: 180px;
        }
    }

    &[class*='h-background'] &--form &__content {
        @include min(sm) {
            padding-top: 360px;
        }
    }

    &[class*='h-background'] &--form2 &__content,
    &[class*='h-background'] &--form2 &__title-half {
        @include min(sm) {
            padding-top: 225px;
        }
    }

    &__title-half > * {
        position: sticky;
        top: 200px;
    }

    &__logo {
        padding-top: 60px;
        padding-bottom: 90px;

        @include min(sm) {
            padding-top: 120px + 49px;
            padding-bottom: 180px;
        }
    }

    &--form &__logo {
        padding-top: 0;
        padding-bottom: 0;
    }

    &__label-wrap > *,
    body.loaded &[data-scroll-anim-active] &__label-wrap > * {
        @include min(sm) {
            transform: translateX(-105%);
            animation-name: enquireTitle;
            animation-timing-function: var(--scroll-ease);
            animation-duration: var(--scroll-duration);
            animation-fill-mode: forwards;
            animation-delay: 450ms;
        }
    }

    body:not(.loaded) &__label-wrap > * {
        @include min(sm) {
            animation-delay: calc(var(--scroll-load-delay) + 450ms);
        }
    }

    body.loaded &__label-wrap > * {
        @include min(sm) {
            transform: translateX(-105%);
            animation: none;
        }
    }

    &__title > *,
    &__content-wrap,
    body.loaded &[data-scroll-anim-active] &__title > *,
    body.loaded &[data-scroll-anim-active] &__content-wrap {
        @include min(sm) {
            opacity: 0;
            transform: translateY(80px);
            animation-name: enquireContent;
            animation-timing-function: var(--scroll-ease);
            animation-duration: var(--scroll-duration);
            animation-fill-mode: forwards;
            animation-delay: 825ms;
        }
    }

    body:not(.loaded) &__title > *,
    body:not(.loaded) &__content-wrap {
        @include min(sm) {
            animation-delay: calc(var(--scroll-load-delay) + #{450ms + 75ms});
        }
    }

    body.loaded &__title > *,
    body.loaded &__content-wrap {
        @include min(sm) {
            opacity: 0;
            transform: translateY(80px);
            animation: none;
        }
    }

    @media (prefers-reduced-motion: reduce) {
        &__title > *,
        &__label-wrap > *,
        &__content-wrap {
            opacity: 1 !important;
            animation: none !important;
            transform: none !important;
        }
    }

    body.editmode &__title > *,
    body.editmode &__content-wrap {
        opacity: 1;
        animation: none !important;
        transform: none !important;
    }

    &[style*='scroll-up'] .u-media-bg:after {
        opacity: calc(var(--scroll-up) * var(--media-bg-opacity));
    }

    &__sticky {
        position: sticky;
        top: 0;
        z-index: 1;

        body.loggedin & {
            top: 45px;
        }
    }

    &__sticky-wrap {
        position: relative;
        padding-top: 40px;

        @include min(sm) {
            height: 170px;
            padding-bottom: 40px;
        }
    }

    &__label {
        @include min(sm) {
            position: absolute;
            left: 100%;
            top: 50%;
            transform: translate(15px, -50%);
        }

        &:before {
            content: '';
            position: absolute;
            bottom: -71px;
            left: 0;
            width: 75vw;
            height: 170px;
            background-image: linear-gradient(to top, rgba(#1c1f2a, 0), #1c1f2a 40%);
            display: none;

            @include min(sm) {
                display: block;
            }

            @include min(md) {
                bottom: -58px;
            }

            body.editmode & {
                display: none;
            }
        }
    }

    &__label-wrap {
        position: relative;
        z-index: 1;
        font-size: var(--h2);
        font-weight: 700;
        letter-spacing: -0.05em;
        white-space: nowrap;
        padding: 0 1px 0 0;
        overflow: hidden;
    }

    &__phone {
        font-size: var(--h2);
    }

    &--form2 &__phone {
        font-size: inherit;
    }

    &__content {
        p + form {
            margin-top: 48px;
        }
    }
}
