.u-skip-to-content-btn {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    padding: 10px;
    transform: translateY(-100%);
    transition: transform $userInactive $easeOut;

    @media (prefers-reduced-motion: reduce) {
        transition: none !important;
    }

    &:focus-within {
        transform: none;
        transition-duration: $userActive;
    }
}
