.c-clients {
    &,
    &__bar {
        --transition-duration: #{$userInactive};
    }

    &[class*='h-background'] {
        padding-top: block-space(700);
        padding-bottom: block-space(700);
    }

    &__item svg {
        color: get-color('neutral', 0);
    }

    &__item img,
    &__item svg {
        top: 50%;
        left: 50%;
        width: 60%;
        max-height: 80px;
        transform: translate(-50%, -50%);
        transition: transform var(--transition-duration) $easeOut;

        @media (prefers-reduced-motion: reduce) {
            transition: none !important;
        }

        @include min(sm) {
            width: 50%;
        }
    }

    &__item img {
        filter: grayscale(1);
    }

    &__item[href]:hover {
        --transition-duration: #{$userActive};

        img,
        svg {
            transform: translate(-50%, -50%) scale(0.85);
        }
    }

    &__item {
        position: relative;
        display: block;

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 60%;
            padding-bottom: 60%;
            height: auto;
            transform-origin: center;
            transform: translate(-50%, -50%) scale(0.6);
            border-radius: 50%;
            background-color: get-color('tertiary', '50');
            opacity: 0;
            transition: opacity var(--transition-duration) $easeOut,
                transform var(--transition-duration) $easeOut;

            @media (prefers-reduced-motion: reduce) {
                transition: none !important;
            }

            @include min(sm) {
                width: 50%;
                padding-bottom: 50%;
            }
        }

        &[href]:hover:before {
            opacity: 1;
            transform: translate(-50%, -50%);
        }

        &[href]:hover img {
            filter: grayscale(1) invert(1);
        }

        &[href]:hover svg {
            color: get-color('neutral', 100);
        }
    }

    &__bar &__item {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}
