$ck-primary: #009fdf;
$ck-inactiveSpeed: 250ms;
$ck-activeSpeed: 400ms;
$ck-easing: cubic-bezier(0.3, 0.15, 0.18, 1);
$ck-sm: (768 / 16) * 1em;

.c-cookie-bar {
    bottom: 0;
    left: 0;
    width: 100%;
    position: fixed;
    z-index: 100;
    background-color: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    padding: 25px 0 30px;
    transform: translateY(100%);
    transition: transform $ck-activeSpeed $ck-easing;

    &.is-active {
        transform: translateY(0);
    }

    &__container {
        max-width: 1140px;
        width: 100%;
        margin: 0 auto;
        flex-wrap: wrap;

        @media (min-width: $ck-sm) {
            display: flex;
            align-items: center;
        }

        > * {
            margin: 0 15px;
            flex-basis: 100%;
        }
    }

    &__content {
        h1 {
            font-weight: 700;
            font-size: 24px;
        }

        p {
            font-size: 16px;
        }
    }

    &__btn {
        overflow: visible;
        cursor: pointer;
        -webkit-font-smoothing: inherit;
        letter-spacing: inherit;
        font: inherit;
        display: inline-block;
        min-width: 145px;
        padding: 10px;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        text-decoration: none;
        color: #fff;
        background-color: $ck-primary;
        border: 1px solid $ck-primary;
        transition: background-color $ck-inactiveSpeed $ck-easing,
            color $ck-inactiveSpeed $ck-easing, border-color $ck-inactiveSpeed $ck-easing;
        border-radius: 0;

        &--settings {
            color: $ck-primary;
            background-color: transparent;
        }

        > * {
            pointer-events: none;
        }

        &:hover {
            transition: background-color $ck-activeSpeed $ck-easing,
                color $ck-activeSpeed $ck-easing, border-color $ck-activeSpeed $ck-easing;
            text-decoration: none;
            background-color: transparent;
            color: $ck-primary;
        }

        &--settings:hover {
            background-color: $ck-primary;
            color: #fff;
        }

        &:active {
            outline: none;
            box-shadow: none;
            color: #fff;
            background-color: desaturate(darken($ck-primary, 5%), 5%);
        }

        &:focus {
            outline: 1px solid $ck-primary;
        }

        &-list {
            margin-left: auto;
            padding-left: 15px;
            margin-top: 20px;
            display: flex;
            justify-items: flex-end;
            flex-wrap: wrap;
            align-items: center;
            @media (min-width: $ck-sm) {
                margin-top: 10px;
            }

            &-item {
                flex-basis: 100%;

                .u-btn {
                    font-size: 1.6rem;
                }

                & + & {
                    margin-top: 25px;
                }

                @media (min-width: $ck-sm) {
                    flex-basis: auto;
                    flex-grow: 2;

                    & + & {
                        margin-top: 0;
                    }
                }

                &--right {
                    @media (min-width: $ck-sm) {
                        flex-grow: 1;
                        text-align: center;
                    }

                    .u-btn {
                        font-size: var(--md-font-size);
                        padding-top: 5px;
                    }

                    .u-btn + .u-btn {
                        margin-left: 30px;
                    }
                }
            }
        }
    }
}
