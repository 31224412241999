.c-nav-btn {
    display: flex;
    align-items: center;
    z-index: 2;
    color: get-color('neutral', 100);
    transition: color $userActive $easeOut, background-color $userActive $easeOut;
    background-color: transparent;
    pointer-events: auto;
    padding: 0 0 0 12px;
    transform: translateX(12px);

    @include min(sm) {
        transform: none;
    }

    [data-hide-nav] & {
        color: get-color('neutral', 0);

        @include min(sm) {
            color: get-color('neutral', 0);
            background-color: get-color('neutral', 100);
        }
    }

    &[data-active] {
        color: get-color('neutral', 100);
        background-color: transparent;
    }

    @include min(md) {
        display: none;
    }

    &__label {
        display: flex;
        position: relative;
        overflow: hidden;

        > * {
            display: block;
            padding: 3px;
            transition: transform $userInactive $easeOut;
        }

        &--close {
            position: absolute;
            top: 0;
            left: 0;
            transform: translateX(100%);
        }
    }

    &[data-active] &__label > * {
        transition-duration: $userActive;
    }

    &[data-active] &__label--close {
        transform: none;
    }

    &[data-active] &__label--open {
        transform: translateX(-100%);
    }

    .u-hamburger {
        --burger-size: 48px;
    }
}
