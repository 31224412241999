.c-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 940px;
    width: 100%;
    background-color: transparent;
    padding: 25px 15px;
    opacity: 0;
    transform: translate(-50%, calc(-50% + 50px));
    transition: transform $userInactive $easeOut, opacity $userInactive $easeOut;
    pointer-events: none;

    @include min(sm) {
        padding: 45px 60px 60px;
    }

    @include min(md) {
        padding: 55px 160px 90px;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: #fff;
    }

    &[data-active] {
        transition: transform $userActive $easeOut 150ms, opacity $userActive $easeOut 150ms;
        transform: translate(-50%, -50%);
        opacity: 1;
        pointer-events: auto;
    }

    &__container {
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        pointer-events: none;
        width: calc(100% - 40px);
        height: calc(100% - 136px);
        max-height: 100vh;
        transition: opacity $userInactive $easeOut;
        margin: 68px 20px;

        &:before {
            content: '';
            position: absolute;
            top: -68px;
            left: -20px;
            right: -20px;
            bottom: -68px;
            background-color: rgba(#000, 0.65);
        }

        &[data-active] {
            opacity: 1;
            transform: none;
            pointer-events: auto;
            transition-duration: $userActive;
            z-index: 1099;
        }
    }

    &__close {
        position: absolute;
        top: -80px;
        width: 48px;
        height: 48px;
        right: 0;
        padding: 20px;
        color: #fff;
        background-color: get-color('secondary', '50');
        transform: translateY(60px);
        opacity: 0;
        transition: color $userInactive $easeOut, background-color $userInactive $easeOut,
            transform $userInactive $easeOut, opacity $userInactive $easeOut;

        svg {
            fill: currentColor;
            width: 20px;
            height: 20px;
        }

        &:hover {
            color: get-color('secondary', '50');
            background-color: #fff;
            transition: color $userActive $easeOut, background-color $userActive $easeOut,
                transform $userActive $easeOut, opacity $userActive $easeOut;
        }
    }

    &[data-active] &__close {
        opacity: 1;
        transform: none;
        transition: color $userActive $easeOut, background-color $userActive $easeOut,
            transform $userActive $easeOut 180ms, opacity $userActive $easeOut 180ms;
    }

    &__content {
        position: relative;
        z-index: 1;
    }

    &__btn-list {
        display: flex;
        justify-content: center;

        > * + * {
            margin-left: 10px;
        }
    }

    &__header {
        @include min(sm) {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        > * + * {
            padding-top: 0;

            @include min(sm) {
                margin-left: 40px;
            }
        }
    }

    &__icon {
        color: get-color('neutral', '100');
        margin: 0 auto 15px;

        @include min(sm) {
            margin: 0;
        }

        &,
        svg {
            width: 75px;
            height: 75px;

            @include min('xxs') {
                width: 110px;
                height: 110px;
            }
        }
    }
}
