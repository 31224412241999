button {
    border: 0;
    cursor: pointer;

    &:focus,
    &:active {
        outline: none;
        box-shadow: none;
    }

    &.focus-visible {
        outline: 2px solid get-color('uniques', 'focus');
        outline-offset: 2px;
    }

    &:focus-visible {
        outline: 2px solid get-color('uniques', 'focus');
        outline-offset: 2px;
    }
}
