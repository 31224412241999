.u-sticky {
    @include min(sm) {
        position: sticky;
        top: 180px;
    }

    &--top {
        top: 40px;

        body.loggedin & {
            top: 40px + 48px;
        }
    }

    &--padded {
        @include min(sm) {
            padding-bottom: 200px;
        }
    }
}
