// @each $themeName, $themeMap in $themes {
$themeMap: map-get($themes, default);

@each $colorName, $colorVariants in $themeMap {
    @each $variantName, $color in $colorVariants {
        .h-background--#{$colorName}-#{$variantName} {
            --h-background-color: #{get-color($colorName, $variantName)};

            background-color: get-color($colorName, $variantName);
        }
    }
}
