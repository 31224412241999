@keyframes theBriefLogo {
    0% {
        opacity: 0;
        transform: scale(0.6);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

.c-the-brief {
    position: relative;
    padding-top: 70px;
    // padding-bottom: 70px;
    z-index: 1;

    @include min(sm) {
        padding-top: 130px;
        padding-bottom: 130px;
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 130px);

        + * {
            position: relative;
            z-index: 1;
        }
    }

    &__image {
        position: relative;
        width: 100%;
        z-index: 1;
        margin-top: block-space(400);

        @include min(md) {
            margin-top: 0;
        }

        &:before {
            @include min(sm) {
                content: '';
                display: block;
                padding-bottom: 300 / 940 * 100%;
            }
        }

        .u-media:after {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            height: 55%;
            width: 100vw;
            background-color: get-color('neutral', 0);
            transform: translateX(-50%);

            @include min(sm) {
                display: none;
            }
        }

        .u-media__wrap {
            z-index: 1;
        }

        > * {
            @include min(sm) {
                position: absolute;
                left: 0;
                width: 100%;
                max-width: $contentWidth;
                transform: translateX(-40%);
                bottom: -130px;
            }
        }

        &--roundal {
            @include min(sm) {
                margin-top: 35px;
            }

            > * {
                @include min(sm) {
                    bottom: -130px;
                    left: -40px;
                    width: 66.666%;
                    transform: none;
                }
            }
        }
    }

    &__logo {
        width: 190px;
        background-color: #fff;
        border-radius: 50%;
        padding: 40px;
        transform: translate(-50%, -50%);

        @include min(sm) {
            position: static;
            top: 0;
            width: 235px;
            transform: none;
            margin-bottom: 20px;
        }
    }

    &__logo,
    body.loaded &__project-details[data-scroll-anim-active] &__logo {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.6);
        animation-name: theBriefLogo;
        animation-timing-function: var(--scroll-ease);
        animation-duration: var(--scroll-duration);
        animation-fill-mode: forwards;
    }

    body:not(.loaded) &__logo {
        animation-delay: var(--scroll-load-delay);
    }

    body.loaded &__logo {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.6);
        animation: none;
    }

    &__logo {
        @media (prefers-reduced-motion: reduce) {
            animation: none !important;
            transform: none !important;
            opacity: 1 !important;
        }

        body.editmode & {
            animation: none !important;
            transform: none !important;
            opacity: 1 !important;
        }
    }

    &__container {
        .c-stats {
            &__title {
                font-size: var(--h2);
            }
        }
    }

    &__project-details {
        padding-top: block-space(300);

        @include min(sm) {
            padding-top: 0;
        }
    }
}
