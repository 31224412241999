.c-content {
    &[class*='h-background'] {
        padding-top: 80px;
        padding-bottom: 100px;

        @include min(sm) {
            padding-top: 200px;
            padding-bottom: 200px;
        }
    }
}
