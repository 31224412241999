.u-background-shape {
    display: none;
    position: absolute;
    background-color: currentColor;
    pointer-events: none;
    z-index: -1;

    body.editmode & {
        display: none !important;
    }

    @include min(md) {
        display: block;
    }

    &__container {
        position: relative;
    }

    &__wrap {
        overflow-x: hidden;
    }

    &[data-shape='circle'],
    &[data-shape='diamond'] {
        top: 50%;
        left: 0;

        &:before {
            content: '';
            display: block;
            padding-bottom: 100%;
        }
    }

    &[data-shape='circle'] {
        width: 200%;
        border-radius: 50%;
        transform: translate(10%, -50%);
    }

    &[data-shape='diamond'] {
        width: 150%;
        transform: translate(25%, -50%) rotate(45deg);
    }

    &[data-align='left'] {
        left: auto;
        right: 0;
    }

    &[data-align='left'][data-shape='circle'] {
        transform: translate(-10%, -50%);
    }

    &[data-align='left'][data-shape='diamond'] {
        transform: translate(-25%, -50%) rotate(45deg);
    }
}
