//====================
//  White space
//====================

@mixin space {
    @each $size, $space in $spaceScale {
        --space-#{$size}: #{$space};
    }

    @each $size, $space in $blockSpaceScale {
        --block-space-#{$size}: #{$space};
    }
}

@function space($size) {
    @return var(--space-#{$size});
}

@function block-space($size) {
    @return var(--block-space-#{$size});
}
