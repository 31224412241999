/**
 * <div class="o-hamburger">
 *   <div><span></span></div>
 * </div>
**/

.u-hamburger {
    --burger-size: 60px;
    --burger-thickness: 2px;
    --height: calc(var(--burger-size) / 3);
    --width: calc(var(--burger-size) / 2);
    --transition-duration: #{$userInactive};

    position: relative;
    width: var(--burger-size);
    height: var(--burger-size);

    > * {
        pointer-events: none;
    }

    &,
    &:hover,
    &:focus,
    &:active {
        border: 0;
    }

    div {
        position: absolute;
        height: var(--height);
        width: var(--width);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    div:before,
    div:after,
    div span {
        content: '';
        position: absolute;
        width: 100%;
        height: var(--burger-thickness);
        background-color: currentColor;
        left: 0;
        transition: transform var(--transition-duration) $easeOut,
            opacity var(--transition-duration) $easeOut,
            background-color var(--transition-duration) $easeOut;
    }

    div:before {
        top: 0;
    }

    div:after {
        bottom: 0;
    }

    div span {
        top: 50%;
        transform: translateY(-50%);
        opacity: 1;
    }

    &[data-active] div:before,
    &[data-active] div:after,
    &[data-active] div span {
        --transition-duration: #{$userActive};
    }

    &[data-active] div:before {
        transform: translateY(calc(var(--height) / 2)) rotate(45deg);
    }

    &[data-active] div span {
        opacity: 0;
        transform: translateY(-50%) scaleX(0.1);
    }

    &[data-active] div:after {
        transform: translateY(calc(var(--height) / -2 + var(--burger-thickness))) rotate(-45deg);
    }
}
