.u-mac-image {
    &__bg {
        --image-width: 924;
        --image-height: 534;
    }

    &__screen {
        --image-width: 696;
        --image-height: 436;

        position: absolute;
        top: 6.367041198501873%;
        left: 50%;
        transform: translateX(-50%);
        width: 75.1082251082251%;
    }
}
