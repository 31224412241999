a {
    position: relative;
    display: inline-block;
    background-color: transparent;
    color: currentColor;
    text-decoration: none;
    -webkit-text-decoration-skip: objects;
    transition: color $userInactive $easeOut;
    z-index: 0;

    @media (prefers-reduced-motion: reduce) {
        transition: none !important;
    }

    &:not([class]) {
        text-decoration: underline;

        @include min(sm) {
            white-space: nowrap;
            text-decoration: none;
        }
    }

    .u-nav &:not([class]) {
        text-decoration: none;
    }

    &:not([class]):after {
        @include min(sm) {
            content: '';
            position: absolute;
            bottom: 0.1em;
            width: 100%;
            height: 1px;
            left: 50%;
            transform: translateX(-50%);
            transition: transform $userInactive $easeOut;
            background-color: currentColor;
        }

        @media (prefers-reduced-motion: reduce) {
            transition: none !important;
        }
    }

    &:hover {
        transition-duration: $userActive;
    }

    &:not([class]):hover:after {
        transform: translateX(-50%) scaleX(0.8);
    }

    &:focus {
        outline: none;
    }

    &:focus-visible {
        outline: 2px solid get-color('focus');
        outline-offset: 2px;
    }

    &.focus-visible {
        outline: 2px solid get-color('focus');
        outline-offset: 2px;
    }
}
