//====================
//	Font Families
//====================

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/gilroy-bold.woff2') format('woff2'),
        url('../fonts/gilroy-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/gilroy-regular.woff2') format('woff2'),
        url('../fonts/gilroy-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
