.c-image-grid {
    & + & {
        margin-top: space(800);
    }

    @include max(sm) {
        margin-top: block-space(200);

        + * {
            margin-top: block-space(200);
        }
    }
}
