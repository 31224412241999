.c-5050 {
    &:not(.c-5050--multiple) &__container {
        > * {
            &:first-child {
                .u-media {
                    @include min(sm) {
                        padding-right: block-space(400);
                    }
                }
            }

            &:last-child {
                .u-media {
                    @include min(sm) {
                        padding-left: block-space(400);
                    }
                }
            }
        }
    }

    @include max(sm) {
        &--image-right {
            .c-5050__container {
                > * {
                    &:first-child {
                        order: 1;
                    }

                    &:last-child {
                        order: 0;
                    }
                }
            }
        }
    }

    &--multiple &__content:last-child {
        @include min(sm) {
            margin-left: block-space(400);
        }
    }

    &__content {
        position: relative;
        z-index: 1;
    }

    .l-halves-right-bleed > &__content:first-child {
        padding-right: 30px;
    }
}
