.c-property-carousel {
    .c-hero + & {
        margin-top: 0;
        padding: 0;

        @include max(sm) {
            background-color: var(--neutral-0);
            padding: 24px 0 88px;
        }

        @include min(sm) {
            position: relative;
            z-index: 2;
            height: 0;

            > * {
                transform: translateY(-50%);
            }
        }
    }

    + .c-the-brief {
        margin-top: 0;

        @include min(sm) {
            padding-top: 130px + 96px;
        }
    }

    &__container {
        display: flex;
    }
    .c-slider__dots {
        bottom: -40px;
        @include max(sm) {
            left: 42%;
        }
    }
    .c-slider__arrow--prev {
        svg {
            color: black;
            width: 48px;
            height: 48px;
            border-radius: 30px;
            background: #fff;
        }
        width: 48px;
        height: 48px;
        font-size: 0;
        line-height: 0;
        left: 10%;
        position: absolute;
        top: 50%;
        @include max(sm) {
            bottom: -88px;
            top: unset;
            left: 0%;
        }
        display: block;

        padding: 0;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);

        cursor: pointer;

        color: transparent;
        border: none;
        outline: none;
        background: transparent;
    }
    .c-slider__arrow--next {
        svg {
            color: black;
            width: 48px;
            height: 48px;
            border-radius: 30px;
            background: #fff;
        }
        width: 48px;
        height: 48px;
        font-size: 0;
        line-height: 0;
        right: 10%;

        position: absolute;
        top: 50%;
        @include max(sm) {
            bottom: -88px;
            top: unset;
            right: 0%;
        }
        display: block;

        padding: 0;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);

        cursor: pointer;

        color: transparent;
        border: none;
        outline: none;
        background: transparent;
    }
    &__flashTag {
        text-wrap: nowrap;
        display: flex;
        width: 145px;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 16px;
        background: #e7bb41;
        margin-bottom: 24px;
        color: var(--primary-raisin-black, #1c1f2a);
        font-family: Gilroy;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.96px;
        text-transform: uppercase;
        @include max(sm) {
            margin-bottom: 24px;
        }
    }
    &__title {
        margin-bottom: 15px;

        color: var(--primary-raisin-black, #1c1f2a);
        font-family: Gilroy;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        align-self: stretch;
        line-height: normal;
        @include max(sm) {
            font-size: 32px;
        }
    }
    &__body {
        margin-bottom: 24px;
        color: var(--primary-raisin-black, #1c1f2a);
        font-family: Gilroy;
        font-size: 18px;
        font-style: normal;
        align-self: stretch;
        font-weight: 400;
        line-height: 28px;
        @include max(sm) {
            margin-bottom: 24px;
        }
    }
    &__button {
        float: left;
    }
    &__content {
        font-size: 2rem;
        padding: 32px;
        width: 55%;
        display: flex;
        flex-direction: column;
        @include max(sm) {
            width: 100%;
            padding: 16px;
        }
    }
    &__image {
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        width: 45%;
        picture {
            display: flex;
            width: 100%;
            height: 100%;
        }
        @include max(sm) {
            width: 100%;
        }
    }
    &__container {
        @include max(sm) {
            flex-direction: column;
        }
    }
}
