h2 {
    color: get-color('neutral', 0);
}

.h-text--neutral-100 h2 {
    color: get-color('neutral', 100);
}

h2 + p {
    margin-top: space(800) !important;
}

h3 + p {
    margin-top: space(600) !important;
}

.ccm-page .alert {
    padding: 1rem;
    text-align: center;
    position: fixed;
    z-index: 100;
    width: 100%;
    &.alert-success {
        background-color:Green;
        color : White;
    }
    &.alert-error,
    &.alert-danger {
        background-color:darkred;
        color : White;
    }
    &.alert-info {
        background-color:lightblue;
        color : darkblue;
    }
}