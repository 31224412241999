.u-background-cut {
    position: relative;
    padding-top: block-space(400);

    @include min(sm) {
        padding-top: block-space(700);
    }

    > * {
        position: relative;
        z-index: 1;
    }

    &:after {
        position: absolute;
        z-index: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40px;
        background-color: get-color('backgroundColor');

        @include min(sm) {
            content: '';
        }
    }

    &__content {
        padding-bottom: 30px;

        @include min(sm) {
            padding-bottom: 40px + 10px;
        }

        h3 + * {
            margin-top: space(800) !important;
        }
    }

    a[href] {
        --transition-duration: #{$userInactive};

        &:hover {
            --transition-duration: #{$userActive};
        }

        h3 {
            --line-height: 4px;

            position: relative;
            padding-top: calc(var(--line-height) + #{space('500')});
            overflow: hidden;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 140px;
                height: var(--line-height);
                background-color: currentColor;
                transform-origin: left;
                transform: translateX(-100%);
                transition: transform var(--transition-duration) $easeOut;
            }
        }

        &:hover h3:before {
            transform: none;
        }
    }

    a[href] img {
        transition: transform var(--transition-duration) $easeOut;
        transform-origin: center;
    }

    a[href]:hover img {
        transform: scale(1.1);
    }

    &[data-cut='top'] {
        padding-top: 0;
        padding-bottom: block-space(600);

        @include min(md) {
            padding-bottom: block-space(1000);
        }

        &:after {
            bottom: auto;
            top: 0;
            height: 200px;
        }
    }

    &__mobile-header {
        @include min(sm) {
            display: none;
        }
    }

    &__header {
        display: none;

        @include min(sm) {
            display: block;
        }

        + * {
            @include max(sm) {
                margin-top: 0;
            }
        }
    }
}
