.c-map {
    position: relative;
    min-height: 500px;
    width: 100%;

    > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
