//====================
// timings
//====================
// these timings are for the basic $easout easing

// active is for a state change anumation on positive interaction
// inActive is for reverse state change animation, for instance when someone hovers off a button
$userActive: 400ms;
$userInactive: 250ms;

// basic scroll animation timings
$scrollIn: 1250ms;
$scrollInitialDelay: 300ms;
$scrollDelayInc: 150ms;

// delay for enabling animations on scroll (javascript triggered) instead of on page load (javascript failed)
$scrollLoadedDelay: 250ms;
