body.loaded [data-paralax='fixed'] {
    [data-paralax-item] {
        @include min(md) {
            height: 100vh;
        }
    }
}

body.loaded [data-paralax='offset'] {
    [data-paralax-item] {
        transform: translateY(calc(var(--paralax-offset, 0%) * -1));
    }

    &[style*='paralax-max'] [data-paralax-item] {
        transform: translateY(
            Max(calc(var(--paralax-offset, 0%) * -1), calc(var(--paralax-max) * -1))
        );
    }
}
