//====================
// Typography tools
//====================

// creates header custom css vars
@mixin _headerSizes($list) {
    $keys: map-keys($list);

    @each $header in $keys {
        --#{$header}: #{map-get($list, $header)};
    }
}

// setups font size custom css vars in global vars
@mixin fontSizes {
    --font-size: #{$fontSize};
    --md-font-size: #{$mdFontSize};

    $headerbreakPoints: map-keys($headerSizes);

    @each $breakpoint in $headerbreakPoints {
        $headers: map-get($headerSizes, $breakpoint);

        @if $breakpoint == 'base' {
            @include _headerSizes($headers);
        } @else if $breakpoint == 'md' {
            @include min(md) {
                @include _headerSizes($headers);
            }
        }
    }
}

// allows you to truncate a single line on text
@mixin text-truncate() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
