.c-hero {
    &__container {
        min-height: 90vh;
        align-items: center;
    }

    &__content {
        opacity: var(--hero-opacity, 1);
        transition: opacity 10ms;
    }

    &[data-size='narrow'] &__container {
        min-height: 475px;
        padding-top: 80px;

        @include min(sm) {
            padding-top: 160px;
            min-height: 630px;
        }

        h1 {
            position: relative;
        }

        .u-small-header {
            position: absolute;
            left: 0;
            top: -25px;
        }
    }

    &__paragraph {
        font-size: var(--h4);
        display: none;

        @include min(sm) {
            display: block;
        }
    }

    body.loaded &[data-paralax='fixed'] [data-paralax-item] {
        height: 100%;
    }

    &[data-size='narrow']:has(+ .c-property-carousel) &__container {
        @include min(sm) {
            padding-bottom: 130px;
        }
    }
}
