//====================
// Ratio
//====================
// calculate a ratio for padding-bottom tricks

@function ratio-no-unit($width, $height) {
    @return ($height / $width) * 100;
}

@function ratio($width, $height) {
    @return ratio-no-unit($width, $height) * 1%;
}
